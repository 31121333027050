import styled, { css } from 'styled-components';
import { path } from 'ramda';
import { GestureAnimation } from '@nn-virtual-pen/education/data-access';

export const AnimationControlWrapper = styled.div`
  position: absolute;
  top: 95px;
  z-index: 10;
  right: 30px;

  @media ${path(['theme', 'media', 'desktop'])} {
    right: 27%;
  }
`;

export const LottieWrapper = styled.div<{ gesture: GestureAnimation }>`
  position: absolute;

  ${({ gesture }) => {
    if (gesture === GestureAnimation.SlideLeft) {
      return css`
        bottom: 10%;
        left: calc(50% + 50px);
      `;
    } 
    if (gesture === GestureAnimation.SlideRight) {
      return css`
        bottom: 10%;
        right: calc(50% + 50px);
      `;
    } 
    return css`
      left: calc(50% + 50px);
      transform: translate(0, -50%);
      top: 50%;
    `;
  }}
`;

export const HiddenWrapper = styled.div`
  z-index: ${path(['theme', 'zIndex', 'gesture'])};
`;
