import React, { useState, FC } from 'react';
import { Twirl as Hamburger } from 'hamburger-react';
import { theme } from '@nn-virtual-pen/ui';
import * as Styled from './mobile-menu.styled'
import { useSpring } from '@react-spring/web';
import { getRoutesConfig, Routes } from '@nn-virtual-pen/education/utils';
import { useTranslate } from '@nn-virtual-pen/education/data-access';
import { ActionButton, ActionButtonVariant, ActionButtonSize } from '@nn-virtual-pen/ui';

interface MobileMenuProps {
  onClick: (routeName: Routes) => void
}

export const MobileMenu: FC<MobileMenuProps> = ({ onClick }) => {
  const { t } = useTranslate();
  const [opened, setOpened] = useState(false);
  const config = getRoutesConfig();
  const tableStyle = useSpring({
    maxHeight: `${opened ? config.length * 40 : 0}px`,
  });

  return (
    <>
      <Styled.MenuList style={tableStyle} onClick={() => setOpened(false)}>
        {config.map(({ route, name, IconComponent }) => {
          const isListItem = [Routes.Learning, Routes.Support].includes(name)
          const Wrapper: any = isListItem
            ? Styled.MenuListItem
            : Styled.MenuRouterLink;

          const props = isListItem
            ? { onClick: () => onClick(name) }
            : { to: route };

          return (
            <Wrapper {...props} key={route}>
              <ActionButton
                variant={ActionButtonVariant.secondary}
                size={ActionButtonSize.navigation}
                IconComponent={IconComponent}
                fullWidth
              >
                {t(name as string)}
              </ActionButton>
            </Wrapper>
          );
        })}
      </Styled.MenuList>
      <Hamburger
        size={20}
        color={theme.palette.nnBlue}
        toggled={opened}
        toggle={setOpened}
      />
    </>
  );
};

export default MobileMenu;
