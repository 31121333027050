import { values } from 'ramda';
import { Home, TrendingUp } from 'styled-icons/boxicons-regular';
import { QuestionCircle } from 'styled-icons/bootstrap';

export enum Routes {
  Home = 'home_header',
  Learning = 'learning_header',
  Configure = 'configure_header',
  Support = 'support_header',
}

export enum RoutePath {
  Welcome = '/',
  Configure = '/configure/',
  Home = '/home/',
  Learning = '/learning/',
  Support = '/support/',
  Congratulations = '/congratulations/',
  ExceedLimit = '/exceed-limit'
}

export const routesConfig = {
  [Routes.Home]: {
    route: RoutePath.Home,
    name: Routes.Home,
    IconComponent: Home,
  },
  [Routes.Learning]: {
    route: RoutePath.Learning,
    name: Routes.Learning,
    IconComponent: TrendingUp,
  },
  [Routes.Support]: {
    route: RoutePath.Support,
    name: Routes.Support,
    IconComponent: QuestionCircle,
  },
  // [Routes.Configure]: {
  //   route: RoutePath.Configure,
  //   name: Routes.Configure,
  //   IconComponent: QuestionCircle,
  // },
};

export const getRoutesConfig = () => values(routesConfig);
