import React, { FC } from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { GlobalStyle } from './global-styles';
import { theme } from './theme.consts'

export const ThemeProvider: FC = ({ children }) => (
  <StyledProvider theme={theme}>
    <GlobalStyle />
    {children}
  </StyledProvider>
);
