import styled from 'styled-components';
import { path, prop } from 'ramda'
import { Close } from 'styled-icons/zondicons'

export const Dialog = styled.div`
  width: 450px;
  max-width: 90%;
  background: white;
  border: 1px solid ${path(['theme', 'palette', 'nnBlue'])};
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${path(['theme', 'zIndex', 'modal'])};
  text-align: center;
  box-shadow: 0 0 30px 10px ${path(['theme', 'palette', 'disabledText'])};
`;

export const Icon = styled.div<{ color: string }>`
  width: 100px;
  margin: 0 auto;
  color: ${prop('color')};
`;

export const Title = styled.div`
  margin-top: 25px;
  font-size: 20px;
  font-weight: bold;
  color: ${path(['theme', 'palette', 'nnBlue'])};
`;

export const Description = styled.div`
  margin-top: 30px;
  font-size: 11px;
  color: ${path(['theme', 'palette', 'disabledText'])};
  padding: 0 10%;
  white-space: pre-wrap;
`;

export const TopModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
  width: 15px;
`;

export const CloseButton = styled(Close)`
  cursor: pointer;
`;

export const AdditionalButtons = styled.div`
  margin-top: 30px;
`;

export const AutoClose = styled.div`
  font-size: 9px;
  margin-top: 8px;
  color: ${path(['theme', 'palette', 'disabledText'])};
`;
