/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 *
 *
 * IMPORTANT NOTE: This generated api didn't get downloading CSV so had changed!
 */

export interface PenDto {
  country?: string | null;
  pen?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface LinkDto {
  link?: string | null;
}

export interface PenDataForCsv {
  country?: string | null;
  pen?: string | null;
  amount?: number;
}

export interface TokenDto {
  token?: string | null;
}

export interface PenResponseDto {
  country?: string | null;
  pen?: string | null;
  message?: string | null;
}

export interface AdminCodeDto {
  code: number;
}

export interface EmailDto {
  email?: string | null;
}

export type RequestParams = Omit<RequestInit, 'body' | 'method'> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

enum BodyType {
  Json,
  FormData,
  Csv,
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType = null as any;
  private securityWorker:
    | null
    | ApiConfig<SecurityDataType>['securityWorker'] = null;

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(
        Array.isArray(query[key]) ? query[key].join(',') : query[key]
      )
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === 'object' && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key)
          )
          .join('&')}`
      : '';
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
    [BodyType.Csv]: JSON.stringify,
    [BodyType.FormData]: (input: any) =>
      Object.keys(input).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
      }, new FormData()),
  };

  private mergeRequestOptions(
    params: RequestParams,
    securityParams?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(
    response: Response
  ): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = (null as unknown) as T;
    r.error = (null as unknown) as E;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean
  ): Promise<HttpResponse<T>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker
        ? this.securityWorker(this.securityData)
        : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      if (bodyType === BodyType.Csv) {
        return await response.blob() as any;
      }
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Education API
 * @version v1
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Education
     * @name GenerateCodeCreate
     * @request POST:/api/generate-code
     * @secure
     */
    generateCodeCreate: (data: PenDto, params?: RequestParams) =>
      this.request<LinkDto, ProblemDetails>(
        `/api/generate-code`,
        'POST',
        params,
        data,
        BodyType.Json,
        true
      ),

    /**
     * No description
     *
     * @tags Education
     * @name GenerateCodeCsvCreate
     * @request POST:/api/generate-code-csv
     * @secure
     */
    generateCodeCsvCreate: (data: PenDataForCsv, params?: RequestParams) =>
      this.request<any, ProblemDetails>(
        `/api/generate-code-csv`,
        'POST',
        params,
        data,
        BodyType.Csv,
        true
      ),

    /**
     * No description
     *
     * @tags Education
     * @name GenerateCodeTokenCreate
     * @request POST:/api/generate-code-token
     * @secure
     */
    generateCodeTokenCreate: (data: TokenDto, params?: RequestParams) =>
      this.request<TokenDto, ProblemDetails>(
        `/api/generate-code-token`,
        'POST',
        params,
        data,
        BodyType.Json,
        true
      ),

    /**
     * No description
     *
     * @tags Education
     * @name RedeemCodeCreate
     * @request POST:/api/redeem-code
     * @secure
     */
    redeemCodeCreate: (data: TokenDto, params?: RequestParams) =>
      this.request<PenResponseDto, ProblemDetails>(
        `/api/redeem-code`,
        'POST',
        params,
        data,
        BodyType.Json,
        true
      ),

    /**
     * No description
     *
     * @tags Education
     * @name CodeCreate
     * @request POST:/api/code
     * @secure
     */
    codeCreate: (data: AdminCodeDto, params?: RequestParams) =>
      this.request<any, ProblemDetails>(
        `/api/code`,
        'POST',
        params,
        data,
        BodyType.Json,
        true
      ),

    /**
     * No description
     *
     * @tags Education
     * @name ValidateSignupEmailCreate
     * @request POST:/api/validate-signup-email
     * @secure
     */
    validateSignupEmailCreate: (data: EmailDto, params?: RequestParams) =>
      this.request<any, ProblemDetails>(
        `/api/validate-signup-email`,
        'POST',
        params,
        data,
        BodyType.Json,
        true
      ),
  };
}
