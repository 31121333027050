import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { AnimationAction, AnimationMixer } from 'three';
import { useFrame, useThree } from 'react-three-fiber';
import { useDrag } from 'react-use-gesture';
import { AnimationState } from '@nn-virtual-pen/education/ui';
import { animationDragSpeed } from '../model-viewer.consts';
import { GestureAnimation } from '@nn-virtual-pen/education/data-access';

const DEFAULT_START_AT = 0.02;

export const useAnimation = ({
  animationConfig,
  animationState,
  animations,
  root,
  onAnimationStart,
  onAnimationStop,
  onAnimationLoaded,
  setProgress,
  productMaterial,
  onGestureCompleted,
  allowRotation,
}) => {
  const mixer = useRef<AnimationMixer>(new AnimationMixer(null));
  const [animation, setAnimation] = useState<AnimationAction>();
  const { gl } = useThree();

  const startAt = useMemo(() => animationConfig.startAt || DEFAULT_START_AT, [
    animationConfig.startAt,
  ]);

  const finishAt = useMemo(
    () => {
      if (animationConfig.finishAt !== 'None') {
        return animationConfig.finishAt;
      }
      return animation ? animation.getClip().duration : null
    },
    [animationConfig.finishAt, animation]
  );

  const duration = useMemo(() => (animation ? finishAt - startAt : null), [
    animationConfig,
    animation,
  ]);

  const onSetProgress = useCallback(() => {
    if (duration) {
      setProgress(((mixer.current.time - startAt) / duration).toFixed(3));
    }
  }, [
    mixer.current.time,
    duration,
    animation,
    ((mixer.current.time - startAt) / duration).toFixed(3),
  ]);

  useFrame((state, delta) => {
    onSetProgress();
    if (animation && animationState === AnimationState.playing) {
      if (mixer.current.time > finishAt) {
        stop(true);
      } else {
        mixer.current.update(delta);
      }
    }
  });

  useEffect(() => {
    if (!animations.length || !animationConfig.name || !root) {
      return;
    }

    const action = animations.find(({ name }) => animationConfig.name === name);

    if (!action && animations && animationConfig.name) {
      throw new Error(
        `The animation has the wrong name. Given "${animationConfig.name}".
          Look in the console to find a correct name for this step.`
      );
    }

    mixer.current = new AnimationMixer(root);
    const newAnimation = mixer.current.clipAction(action);
    setAnimation(newAnimation);
  }, [
    setAnimation,
    animationConfig.name,
    animations,
    root,
    productMaterial,
  ]);

  useEffect(() => {
    if (animation) {
      animation.play();
      setAnimationAtStart();
      animation.paused = true;
      onAnimationLoaded();
    }
  }, [animation]);

  const play = useCallback(() => {
    animation.paused = false;
    animation.play();
    setAnimationAtStart();
    onAnimationStart();
  }, [animation, onAnimationStart]);

  const stop = useCallback(
    (complete?: boolean) => {
      setAnimationAtStart();
      animation.paused = true;
      onAnimationStop(complete);
    },
    [animation, onAnimationStop]
  );

  const setAnimationAtStart = useCallback(() => {
    mixer.current.setTime(startAt);
  }, [animation, mixer.current]);

  useEffect(() => {
    if (animationState === AnimationState.playing) {
      play();
    }

    if (animationState === AnimationState.canceling) {
      stop();
    }
  }, [animation, animationState]);

  const gestureAspect = useMemo(
    () =>
      ({
        [GestureAnimation.DragUp]: -1,
        [GestureAnimation.DragDown]: 1,
        [GestureAnimation.SlideLeft]: -1,
        [GestureAnimation.SlideRight]: 1,
      }[animationConfig.gesture]),
    [animationConfig.gesture]
  );

  useDrag(
    ({ down, movement: [movementRight, movementTop] }) => {
      if (
        !animationConfig.name ||
        animationConfig.gesture === GestureAnimation.TapClick ||
        animationState === AnimationState.playing
      ) {
        return;
      }

      const movementAspect = {
        [GestureAnimation.DragUp]: movementTop,
        [GestureAnimation.DragDown]: movementTop,
        [GestureAnimation.SlideLeft]: movementRight,
        [GestureAnimation.SlideRight]: movementRight,
      }[animationConfig.gesture];

      const move = gestureAspect * (movementAspect / animationDragSpeed);

      animation.paused = false;

      if (
        !allowRotation &&
        move <= finishAt &&
        move > startAt &&
        mixer.current.time >= startAt
      ) {
        mixer.current.setTime(move);
      }

      if (down && move / finishAt > 9 / 10) {
        onGestureCompleted();
        setProgress(1);
      }
    },
    {
      domTarget: gl.domElement,
      initial: () => {
        const time = gestureAspect * mixer.current.time * animationDragSpeed;
        return [time, time];
      },
    }
  );
};
