import React, { FC } from 'react';
import * as Styled from './header.styled';
import { HeaderProps } from './header.types';
import { TranslationKey, useTranslate } from '@nn-virtual-pen/education/data-access';

export const Header: FC<HeaderProps> = ({ children, step }) => {
  const { t } = useTranslate();

  return (
    <Styled.Wrapper>
      {t(TranslationKey.step)} {step}: {children}
    </Styled.Wrapper>
  );
};
