import { useIntl } from 'react-intl';
import { getUserLanguage } from './helpers';
import { TranslationKey } from './translate-provider.types';

interface UseLangResult {
  t: (
    id: TranslationKey | string,
    interpolationValues?: Record<string, string>
  ) => string;
  lang: string;
  isInitialized: boolean;
}

export const useTranslate = (): UseLangResult => {
  const lang = getUserLanguage();
  const { formatMessage, messages } = useIntl();

  const t = (id, interpolationValues) => {
    return formatMessage({ id }, interpolationValues);
  };

  return {
    t,
    lang,
    isInitialized: Object.keys(messages)?.length > 0,
  };
};
