import styled, { StyledComponent } from 'styled-components';
import { prop, path } from 'ramda';

export const Wrapper = styled.div<{ height: string }>`
  position: relative;
  width: 100%;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: ${prop('height')};
  z-index: ${path(['theme', 'zIndex', 'pen'])};
`;

export const AnimationControl = styled.div<{ hidden: boolean }>`
  position: absolute;
  z-index: ${path(['theme', 'zIndex', 'pen'])};
  top: 20px;
  right: 10px;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  display: flex;
  flex-direction: column;
`;

export const CanvasWrapper = styled.div<{ rotation: number }>`
  height: 100%;
  transform: rotate(${prop('rotation')}deg);
`;

type AnimationProgressType = StyledComponent<"div", any, {
  progress: number;
}, never>

export const AnimationProgress: AnimationProgressType =
  styled.div.attrs<{ progress: number }>(
    ({ progress }) => ({
      style: {
        width: `calc(${progress} * 100%)`,
      },
    })
  )`
    height: 2px;
    background-color: ${path(['theme', 'palette', 'nnBlue'])};
  `;
