import React, { memo, useState, FC, useEffect, useMemo } from 'react';
import * as Styled from './audio-player.styled';
import mute from 'static/icons/mute.svg';
import voice from 'static/icons/voice.svg';
import { useUserContext, useAudioContext, defaultSrc } from '@nn-virtual-pen/education/data-access';
import { useUpdateEffect } from 'react-use';

interface AudioPlayerProps {
  audioFile: string;
}

export const AudioPlayer: FC<AudioPlayerProps> = memo(({ audioFile }) => {
  const { trackRef } = useAudioContext();
  const [isMuted, setMuted] = useState<boolean>(false);
  const { configuration } = useUserContext();

  const country = useMemo(() => String(configuration.country).toLowerCase(), [
    configuration.country,
  ]);

  const play = () => {
    if (trackRef.current && !isMuted) {
      trackRef.current.currentTime = 0;
      void trackRef.current.play();
    }
  }

  // Replay when we unmute
  useUpdateEffect(() => {
    if (isMuted) {
      return trackRef.current.pause();
    }

    play();
  }, [isMuted]);

  useEffect(() => {
    if (!audioFile) {
      return;
    }

    trackRef.current.src = `/audio/${country}/${audioFile}`;
    play();

    return () => {
      trackRef.current.src = defaultSrc;
    }
  }, [audioFile]);

  if (!audioFile) {
    return null;
  }

  const handleSetMuted = () => {
    setMuted(!isMuted);
  }

  return (
    <div>
      <Styled.Wrapper
        onClick={handleSetMuted}
        size={50}
      >
        <img src={isMuted ? mute : voice} alt={isMuted ? 'muted' : 'voice'} />
      </Styled.Wrapper>
    </div>
  );
});
