import {
  TranslationKey,
  useContentContext,
  useTranslate,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import { Modal } from '@nn-virtual-pen/education/ui';
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { CheckCircle } from 'styled-icons/boxicons-solid';

interface StepModalContextType {
  stepModalOpen: boolean;
  setStepModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CreateStepModalContext = createContext<StepModalContextType>(undefined);

interface StepModalContextProps {
  onComplete?: () => void;
}

export const StepModalContext: FC<StepModalContextProps> = ({
  children,
  onComplete,
}) => {
  const { t } = useTranslate();
  const [stepModalOpen, setStepModalOpen] = useState(false);
  const { completeStep } = useUserContext();
  const { activeStep, setNextStep } = useContentContext();

  const complete = () => {
    completeStep();
    setStepModalOpen(false);
    setNextStep();

    if (onComplete) {
      onComplete();
    }
  };

  return (
    <CreateStepModalContext.Provider
      value={{ setStepModalOpen, stepModalOpen }}
    >
      {children}
      <Modal
        opened={stepModalOpen}
        onClose={complete}
        title={t(TranslationKey.success_text, {
          stepIndex: activeStep.Index,
        })}
        description={activeStep.CompleteStepModal}
        IconComponent={CheckCircle}
        state="success"
        autoClose
        AdditionalButtons={() => (
          <ActionButton
            size={ActionButtonSize.small}
            variant={ActionButtonVariant.primary}
            onClick={complete}
          >
            {t(TranslationKey.close_button)}
          </ActionButton>
        )}
      />
    </CreateStepModalContext.Provider>
  );
};

export const useStepModalContext = () =>
  useContext(CreateStepModalContext) || ({} as StepModalContextType);
