import { useSpring, a } from '@react-spring/web';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ChevronRight } from 'styled-icons/boxicons-regular';
import { theme } from '@nn-virtual-pen/ui';

export interface ExpanderProps {
  expanded: boolean;
  onClick?: () => void;
}

const StyledExpander = styled(a.div)`
  width: 30px;
  cursor: pointer;
`;

export const Expander: FC<ExpanderProps> = ({ expanded, onClick }) => {
  const style = useSpring({
    to: {
      transform: `rotateZ(${expanded ? 90 : 0}deg)`,
      backgroundColor: theme.palette[expanded ? 'selago' : 'white'],
      borderRadius: '50%',
    },
    from: {
      transform: 'rotateZ(0deg)',
      backgroundColor: theme.palette.white
    },
  });

  return (
    <StyledExpander style={style} onClick={onClick}>
      <ChevronRight />
    </StyledExpander>
  );
};

export default Expander;
