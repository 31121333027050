import { useMemo } from 'react';
import { useAuthContext } from '../auth-context';
import { Api } from './api';

export const useApi = (useBasicAuth?: boolean) => {
  const { accessToken } = useAuthContext();
  const getAuthorization = () => {
    if (accessToken) {
      return 'Bearer ' + accessToken
    }
    if (useBasicAuth) {
      return 'Basic ' + process.env.NX_EDUCATION_BASIC_AUTH
    }
  };

  const api = useMemo(
    () =>
      new Api({
        baseUrl: process.env.NX_ADMIN_PANEL_API_BASE_URL || '',
        baseApiParams: {
          headers: {
            'Authorization': getAuthorization(),
            'Content-Type': 'application/json',
            'accept': 'application/json',
          },
        },
      }),
    [accessToken]
  );

  return api;
};
