import React from 'react';
import { StepsList } from '@nn-virtual-pen/education/ui';
import { isEmpty } from 'ramda';
import { ModelViewerWithAnimations } from '../learning-steps-page';
import { StepModalContext } from '../step-modal-context';
import { StepButtons } from './step-buttons';
import * as Styled from './learning-steps-desktop.styled';
import { StepInformation } from '../step-information/step-information';
import { ModelAnimationsContext } from '../model-animations-context';
import { useContentContext } from '@nn-virtual-pen/education/data-access';

export const LearningStepsDesktop = () => {
  const { activeStep } = useContentContext();

  return (
    <Styled.DesktopLayout>
      <StepModalContext>
        <Styled.StepsWrapper>
          <StepsList visible />
        </Styled.StepsWrapper>
        {!isEmpty(activeStep) && (
          <ModelAnimationsContext>
            <Styled.ModelViewer>
              <ModelViewerWithAnimations height="100%" />
            </Styled.ModelViewer>
            <Styled.InfoComponent>
              <Styled.InfoWrapper>
                <StepInformation visible defaultReadMore />
              </Styled.InfoWrapper>
              <StepButtons />
            </Styled.InfoComponent>
          </ModelAnimationsContext>
        )}
      </StepModalContext>
    </Styled.DesktopLayout>
  );
};

export default LearningStepsDesktop;
