import { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import { fitCameraToSelection } from '../helpers';
import * as THREE from 'three';

export const useFitCameraToSelection = (
  camera,
  controls,
  selection,
  animationName,
  centerOffset,
  fitOffset
) => {
  // Trigger the fit camera only when the animation changes
  const prevAnimationName = usePrevious(animationName);
  const initialized = useRef<boolean>(false);

  const fit = useCallback(() => {
    const selectionExists = selection.every((node) => node);

    if (!selectionExists || !controls) {
      return;
    }

    initialized.current = true;

    // console.log('[ModelViewer] useFitCameraToSelection - trigger');

    fitCameraToSelection(
      camera as THREE.PerspectiveCamera,
      controls,
      selection,
      centerOffset,
      fitOffset
    );
  }, [camera, controls, selection, animationName]);

  useEffect(() => {
    if (initialized.current && prevAnimationName === animationName) {
      return;
    }

    fit();
  }, [fit, animationName, prevAnimationName]);

  return { fit };
};
