import styled, { css } from 'styled-components';
import { a } from '@react-spring/web';
import { path } from 'ramda';
import { CheckCircle } from 'styled-icons/fa-solid';

export const Wrapper = styled.div<{ expanded: boolean }>`
  width: 100%;
  cursor: pointer;

  ${({ expanded, theme }) =>
    !expanded &&
    css`
      & + & {
        border-top: 1px solid ${theme.palette.mercuryGrey};
      }
    `}
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 0;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

export const SubTitle = styled.div`
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
`;

export const Steps = styled(a.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
`;

export const Step = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) =>
    theme.palette[active ? 'moreGreyedBg' : 'greyedBg']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  ${({ active, theme }) =>
    !active &&
    css`
      &:hover {
        box-shadow: 0px 0px 4px 4px ${theme.palette.moreGreyedBg};
      }
    `}

  & + & {
    margin-top: 10px;
  }
`;

export const CheckIcon = styled(CheckCircle)`
  color: ${path(['theme', 'palette', 'justGreen'])};
  width: 23px;
`;

export const UncheckIcon = styled.span`
  display: block;
  background-color: ${path(['theme', 'palette', 'silverChalice'])};
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;

export const Line = styled.span<{ active: boolean }>`
  position: relative;
  right: 12px;
  top: 36px;
  width: 2px;
  height: 50px;
  background-color: ${({ theme, active }) =>
    theme.palette[active ? 'justGreen' : 'silverChalice']};
`;

export const IconWrapper = styled.span`
  min-width: 25px;
  display: flex;
  align-items: center;
`;
