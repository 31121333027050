import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { Header } from './header';
import { StepsList } from '@nn-virtual-pen/education/ui';
import { HEADER_HEIGHT, FOOTER_HEIGHT } from './constants';
import { Footer } from './footer';
import { ModelViewerWithAnimations } from '@nn-virtual-pen/education/features/learning-steps-page';
import * as Styled from './learning-steps-mobile.styled';
import { StepModalContext } from '../step-modal-context';
import { Content } from './learning-steps-mobile.types';
import { StepInformation } from '../step-information/step-information';
import { ModelAnimationsContext } from '../model-animations-context';
import { useContentContext } from '@nn-virtual-pen/education/data-access';

export const LearningStepsMobile = () => {
  const [content, setContent] = useState<Content>(Content.ModelViewer);
  const { activeStep } = useContentContext();

  return (
    <Styled.LearningStepsPage>
        <StepsList
          visible={content === Content.StepsList}
          height={`calc(100% - ${FOOTER_HEIGHT}px)`}
          onListItemClick={() => setContent(Content.ModelViewer)}
        />
      {!isEmpty(activeStep) && (
        <StepModalContext onComplete={() => setContent(Content.ModelViewer)}>
          <ModelAnimationsContext>
              <Styled.ModelViewerWrapper visible={content === Content.ModelViewer}>
                <Header onClick={() => setContent(Content.StepsList)} />
                <ModelViewerWithAnimations
                  height={`calc(100% - ${FOOTER_HEIGHT}px)`}
                />
              </Styled.ModelViewerWrapper>
              <StepInformation
                visible={content === Content.StepInformation}
                onClose={() => setContent(Content.ModelViewer)}
              />
            {content !== Content.StepInformation && (
              <Footer {...{ content, setContent }} />
            )}
          </ModelAnimationsContext>
        </StepModalContext>
      )}
    </Styled.LearningStepsPage>
  );
};

export default LearningStepsMobile;
