import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';

interface AudioContext {
  trackRef: MutableRefObject<HTMLAudioElement>;
}

const AudioContext = createContext<AudioContext>(undefined);

// Silent short audio
export const defaultSrc = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'

export const AudioContextProvider: FC = ({ children }) => {
  const trackRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    trackRef.current = new Audio(defaultSrc);
    trackRef.current.autoplay = false;
    void trackRef.current.play();

    return () => {
      trackRef.current.src = defaultSrc;
    }
  }, []);

  return (
    <AudioContext.Provider value={{
      trackRef,
    }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudioContext = () => useContext(AudioContext);
