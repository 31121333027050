import styled from 'styled-components';
import {
  ContentProps,
  TriggerProps,
  TriggerWrapperProps,
} from './drop-down.types';
import { getActiveState, getTextColor } from './helpers';
import { path, pathOr, prop } from 'ramda';

export const Wrapper = styled.button<TriggerWrapperProps>`
  font-family: 'Apis';
  width: 100%;
  height: 37px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1.2px;
  color: ${(props) => getTextColor(props)};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.palette.disabledBg
        : props.theme.palette.mystic};
  ${(props) =>
    props.isMenuOpen ? `outline: ${props.theme.palette.nnBlue} auto 1px;` : ''}
  ${(props) =>
    !props.disabled && props.error
      ? `outline: ${props.theme.palette.error} auto 1px;`
      : ''}
  border-radius: 4px;
  background-color: ${(props) => getActiveState(props)};
  transition: 200ms linear background;
  ${(props) =>
    props.isMenuOpen &&
    `box-shadow: '0 0 0 1px ${props.theme.palette.nnBlue}, 0 4px 11px ${props.theme.palette.nnBlue}';`}

  ${(props) =>
    !props.disabled
      ? `

    cursor: pointer;
    &:hover {
      background: ${
        props.hasValue
          ? props.theme.palette.whisper
          : props.theme.palette.mystic
      };
    }
  `
      : ''}

  @media ${(props) => props.theme.media.desktop} {
    width: ${prop('fullWidth') ? '100%' : pathOr('250px', ['width'])};
  }
`;

export const Content = styled.span`
  display: flex;
`;

export const ValueWrapper = styled.span<Pick<TriggerProps, 'isLoading'>>`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  text-align: ${(props) => (props.isLoading ? 'center' : 'left')};
`;

export const ChevronWrapper = styled.span`
  position: relative;

  svg {
    position: absolute;
    transform: translateY(-50%);
    right: -10px;
    top: 50%;
  }
`;

export const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;

export const Menu = styled.div<Pick<ContentProps, 'fullWidth'>>`
  background-color: ${path(['theme', 'palette', 'whisper'])};
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: left;

  @media ${(props) => props.theme.media.desktop} {
    width: ${(props) => (props.fullWidth ? '100%' : '242px')};
  }
`;

export const ContentWrapper = styled.div<
  Pick<ContentProps, 'width' | 'fullWidth'>
>`
  position: relative;
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.fullWidth ? `width: 100%;` : '')}
`;

export const LoadingIndicator = styled.div`
  & > div {
    position: relative;
    font-size: 6px;
  }
  span {
    margin-top: 5px;
  }
`;
