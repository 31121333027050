import { Styles } from 'react-select';
import { theme } from '../../theme';
import { getBgColor } from './getBgColor';

export const customStyles: Styles = {
  control: () => ({
    opacity: 0,
    height: 0,
    width: 0,
  }),
  container: provided => ({
    ...provided,
    paddingLeft: '9px',
    paddingRight: '9px',
  }),
  menu: () => ({
    width: '100%',
  }),
  option: (provided, { disabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: getBgColor(disabled, isFocused, isSelected),
    color: isSelected ? theme.palette.black : theme.palette.stormGrey,
    textDecoration: (isSelected || isFocused) ? 'underline' : '',
    borderBottom: `1px solid rgba(225,228,237, 0.5)`,
    ':last-of-type': {
      border: 'none',
    },
    fontSize: '11px',
    fontWeight: 300,
    lineHeight: '14px',
    letterSpacing: '1.1px',
    cursor: disabled ? 'not-allowed' : 'default',
    padding: '10px 7px',
    ':active': {
      ...provided[':active'],
      backgroundColor: theme.palette.mystic,
    },
  }),
};
