import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import { create } from 'apisauce';
import {
  ContentType,
  ContentStepType,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { pipe, find, propEq, defaultTo, propOr, dec, inc } from 'ramda';
import {
  getLastStepOfPreviousCategory,
  getFirstStepOfNextCategory,
  addFlagsToSteps,
  addFlagsToCategories,
  getFirstIncompletedStepAndCategory,
} from './content-context.helpers';
import { useAudioContext } from '../audio-context';
import { learningStepNavigationGtmEvent } from '@nn-virtual-pen/utils';

interface CreateContextType {
  content: ContentType;
  isLoading: boolean;
  activeCategory;
  activeStep;
  setPreviousStep;
  setNextStep;
}

const CreateContentContext = createContext<CreateContextType>(undefined);

interface ContentContextProps {
  contentPath: string;
  autoFetch?: boolean;
  defaultContent?: ContentType;
}

export const ContentContext: FC<ContentContextProps> = ({
  children,
  contentPath,
  autoFetch = true,
  defaultContent = [],
}) => {
  const [content, setContent] = useState<ContentType>(defaultContent);
  const [isLoading, setLoading] = useState(false);
  const { step, category, setStepAndCategory, configuration, steps } = useUserContext();
  const { trackRef } = useAudioContext();

  const api = create({
    baseURL: ``,
  });

  const fetchContent = async () => {
    setLoading(true);
    try {
      const response = await api.get(contentPath);
      if (response.ok) {
        setContent(response.data as ContentType);
      }
    } catch {
      // TODO: error handling
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch) {
      void fetchContent();
    }

    learningStepNavigationGtmEvent(
      `${category}.${step}`,
      category,
      step,
      configuration.device,
      configuration.product,
      configuration.country,
      'page_view',
    );
  }, []);

  const activeCategory = pipe(
    addFlagsToCategories,
    find(propEq('Index', category)),
    addFlagsToSteps,
    defaultTo({})
  )(content) as ContentType[0] & {
    isLast: boolean;
    isFirst: boolean;
  };

  const activeStep = pipe(
    propOr([], 'Steps'),
    find(propEq('Index', step)),
    defaultTo({})
  )(activeCategory) as ContentStepType & {
    isLast: boolean;
    isFirst: boolean;
  };

  const setPreviousStep = () => {
    const nextStep = activeStep.isFirst
      ? activeCategory.isFirst
        ? activeStep.Index
        : getLastStepOfPreviousCategory(content, activeCategory)
      : dec(activeStep.Index);

    const nextCategory =
      activeStep.isFirst && !activeCategory.isFirst
        ? dec(activeCategory.Index)
        : activeCategory.Index;

    setStepAndCategory(nextStep, nextCategory);

    learningStepNavigationGtmEvent(
      `${nextCategory}.${nextStep}`,
      nextCategory,
      nextStep,
      configuration.device,
      configuration.product,
      configuration.country,
      'previous_step_button',
    );
  };

  const setNextStep = () => {
    if (activeStep.isLast && activeCategory.isLast) {
      trackRef.current.pause();
      
      const incompleted = getFirstIncompletedStepAndCategory(steps, content)

      if (incompleted && activeStep.Index !== incompleted.step && activeCategory.Index !== incompleted.category) {
        return setStepAndCategory(incompleted.step, incompleted.category);
      }
  
      return navigate('/congratulations/');
    }

    const nextStep = activeStep.isLast
      ? activeCategory.isLast
        ? activeStep.Index
        : getFirstStepOfNextCategory(content, activeCategory)
      : inc(activeStep.Index);

    const nextCategory =
      activeStep.isLast && !activeCategory.isLast
        ? activeCategory.Index + 1
        : activeCategory.Index;

    setStepAndCategory(nextStep, nextCategory);

    learningStepNavigationGtmEvent(
      `${nextCategory}.${nextStep}`,
      nextCategory,
      nextStep,
      configuration.device,
      configuration.product,
      configuration.country,
      'next_step_button',
    );
  };

  return (
    <CreateContentContext.Provider
      value={{
        content,
        isLoading,
        activeCategory,
        activeStep,
        setPreviousStep,
        setNextStep,
      }}
    >
      {children}
    </CreateContentContext.Provider>
  );
};

export const useContentContext = () => useContext(CreateContentContext);
