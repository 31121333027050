import styled from 'styled-components';
import {
  ActionButtonProps,
  ActionButtonSize,
  ActionButtonVariant,
} from './action-button.types';
import { getHeight, getTextSize, getWidth } from './helpers';
import { path } from 'ramda';

export const Button = styled.button<ActionButtonProps>`
  appearance: none;
  background-color: ${({ variant, theme }) =>
    variant === ActionButtonVariant.primary
      ? theme.palette.nnBlue
      : theme.palette.white};
  border: 1px solid ${path(['theme', 'palette', 'nnBlue'])};
  outline: none;
  cursor: pointer;
  padding: 8px
    ${(props) =>
      [ActionButtonSize.small, ActionButtonSize.navigation].includes(props.size)
        ? '10px'
        : '20px'};
  border-radius: ${(props) =>
    props.size === ActionButtonSize.huge ? '26px' : '20px'};
  color: ${({ variant, theme }) =>
    variant === ActionButtonVariant.primary
      ? theme.palette.white
      : theme.palette.nnBlue};
  transition: background-color 0.2s, box-shadow 0.4s;
  width: ${(props) => (props.fullWidth ? '100%' : `${getWidth(props)}px`)};
  height: ${getHeight}px;

  &:disabled {
    color: ${path(['theme', 'palette', 'disabledText'])};
    border-color: ${path(['theme', 'palette', 'disabledBg'])};
    background-color: ${path(['theme', 'palette', 'disabledBg'])};
  }

  &:hover:enabled {
    box-shadow: 0px 5px 3px rgba(159, 188, 235, 0.3);
  }
`;

export const Content = styled.span`
  display: flex;
  align-items: center;
`;

interface TextProps extends Pick<ActionButtonProps, 'bolded' | 'size'> {
  hasIcon: boolean;
}

export const Text = styled.span<TextProps>`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => getTextSize(props.size, props.bolded, props.hasIcon)}
`;

export const IconWrapper = styled.span<
  Pick<ActionButtonProps, 'variant' | 'size'>
>`
  > svg {
    color: ${({ variant, theme }) =>
      variant === ActionButtonVariant.primary
        ? theme.palette.white
        : theme.palette.nnBlue};
    width: ${({ size }) =>
      size === ActionButtonSize.navigation ? '16px' : '18px'};
  }
`;
