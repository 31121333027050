import styled from 'styled-components';
import { path } from 'ramda';

export const IndicatorPack = styled.div`
  position: absolute;
  top: 50%;
  left: 25px;

  @media ${path(['theme', 'media', 'desktop'])} {
    left: 27%;
  }
  transform: translate(0, -50%);
  background-color: ${path(['theme', 'palette', 'selago'])};
  width: 40px;
  border-radius: 20px;
  padding: 10px;
`;

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
`;

export const ActiveDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${path(['theme', 'palette', 'justGreen'])};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Line = styled.div`
  position: relative;
  left: 8px;
  width: 3px;
  height: 30px;
  background-color: white;
`;
