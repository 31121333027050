import React, {
  useContext,
  createContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import { Modal } from '@nn-virtual-pen/education/ui';
import {
  TranslationKey,
  useTranslate,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { InfoCircle } from 'styled-icons/boxicons-solid';
import { PhoneCall } from 'styled-icons/boxicons-regular';
import { Mail } from 'styled-icons/feather';
import * as Styled from './support-context.styled';

interface SupportContextType {
  supportOpen: boolean;
  setSupportOpen: Dispatch<SetStateAction<boolean>>;
}

const CreateSupportContext = createContext<SupportContextType>(undefined);

export const SupportContext: FC = ({ children }) => {
  const { t } = useTranslate();
  const [supportOpen, setSupportOpen] = useState(false);
  const { configuration } = useUserContext();

  return (
    <CreateSupportContext.Provider value={{ supportOpen, setSupportOpen }}>
      {children}
      <Modal
        opened={supportOpen}
        onClose={() => setSupportOpen(false)}
        title={t(TranslationKey.support_text)}
        description={t(TranslationKey.support_text_body)}
        IconComponent={InfoCircle}
        state="warning"
        AdditionalButtons={() => (
          <Styled.ButtonsWrapper>
            <a href={`tel:${configuration.phoneSupport}`}>
              <ActionButton
                size={ActionButtonSize.small}
                width={180}
                variant={ActionButtonVariant.primary}
                IconComponent={PhoneCall}
              >
                {t(TranslationKey.call_button)}
              </ActionButton>
            </a>
            <Styled.Break />
            <a href={`mailto:${configuration.emailSupport}`}>
              <ActionButton
                size={ActionButtonSize.small}
                width={180}
                variant={ActionButtonVariant.primary}
                IconComponent={Mail}
              >
                {t(TranslationKey.email_button)}
              </ActionButton>
            </a>
          </Styled.ButtonsWrapper>
        )}
      />
    </CreateSupportContext.Provider>
  );
};

export const useSupportContext = () => useContext(CreateSupportContext);
