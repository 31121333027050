import React, { FC } from 'react';
import { Logo, LogoSize } from '../logo';
import { Container as StyledContainer } from '../container';
import * as Styled from './header.styled';

interface Props {
  displayOnMobile?: boolean;
}

export const Header: FC<Props> = ({ children, displayOnMobile }) => (
  <>
    <Styled.Wrapper displayOnMobile={displayOnMobile}>
      <StyledContainer isFluid>
        <Styled.Content>
          <Logo size={LogoSize.medium} />
          {children}
        </Styled.Content>
      </StyledContainer>
    </Styled.Wrapper>
    <Styled.NegativeMargin />
  </>
);
