export const getBgColor = (disabled: boolean, isFocused: boolean, isSelected: boolean) => {
  if (isSelected) {
    return '';
  }

  if (isFocused) {
    return '';
  }

  return '';
};
