/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ThemeProvider } from '@nn-virtual-pen/ui';
import { AudioContextProvider, TranslateProvider } from '@nn-virtual-pen/education/data-access';
import {
  DEFAULT_CATEGORY,
  DEFAULT_STEP,
  DEFAULT_STEPS,
  UserContext,
  AuthContextProvider,
} from '@nn-virtual-pen/education/data-access';
import { Debug } from './src/components/debug';
import 'static/fonts/fonts.css';

export const wrapRootElement = ({ element }) => (
  <UserContext>
    <TranslateProvider>
      <AuthContextProvider>
        <ThemeProvider>
          <AudioContextProvider>
            <Debug />
            {element}
          </AudioContextProvider>
        </ThemeProvider>
      </AuthContextProvider>
    </TranslateProvider>
  </UserContext>
);

export const onServiceWorkerUpdateReady = () => {
  window.localStorage.setItem('steps', DEFAULT_STEPS);
  window.localStorage.setItem('step', DEFAULT_STEP);
  window.localStorage.setItem('category', DEFAULT_CATEGORY);
  window.location.reload();
};
