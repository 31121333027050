import styled from 'styled-components';
import { path } from 'ramda';

const toggleControlColor = 'white';
const toggleWidth = 38;
const toggleHeight = 24;
const toggleGutter = 2;
const toggleRadius = '50%';
const toggleControlSpeed = '.15s';
const toggleControlEase = 'ease-in';
const toggleControlSize = toggleHeight - toggleGutter * 2;

export const Wrapper = styled.label`
  position: relative;
  padding-left: ${toggleWidth}px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
`;

export const Control = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${toggleHeight}px;
  width: ${toggleWidth}px;
  border-radius: ${toggleHeight / 2}px;
  background-color: ${path(['theme', 'palette', 'selago'])};
  transition: background-color ${toggleControlSpeed} ${toggleControlEase};

  &:after {
    content: '';
    position: absolute;
    left: ${toggleGutter}px;
    top: ${toggleGutter}px;
    width: ${toggleControlSize}px;
    height: ${toggleControlSize}px;
    border-radius: ${toggleRadius};
    background: ${toggleControlColor};
    transition:
      left ${toggleControlSpeed} ${toggleControlEase},
      background-color ${toggleControlSpeed} ${toggleControlEase};
    box-shadow: 0px 2px 4px rgba(26, 11, 56, 0.3);
  }
`;

export const MaskedInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Control} {
    background-color: ${path(['theme', 'palette', 'selago'])};

    &:after {
      background-color: ${path(['theme', 'palette', 'nnBlue'])};
      left: ${toggleWidth - toggleControlSize - toggleGutter}px;
    }
  }
`;
