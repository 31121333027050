import { AuthContextAction, AuthContextActionType, AuthContextState } from '../auth-context.types';

export const reducer = (state: AuthContextState, action: AuthContextAction): AuthContextState => {
  switch (action.type) {
    case AuthContextActionType.startAuthentication:
      return {
        ...state,
        isLoading: true,
      }
    case AuthContextActionType.reAuthenticate:
    case AuthContextActionType.authenticatePassCode:
    case AuthContextActionType.authenticateToken:
      return {
        ...state,
        isLoading: false,
        token: action.token
      };
    case AuthContextActionType.unauthenticated:
    case AuthContextActionType.signOut:
      return {
        ...state,
        isLoading: false,
        token: null
      };
    default:
      return state;
  }
};
