import styled from 'styled-components';
import { path } from 'ramda';
import {
  BlurredCircleProps,
  BlurredCirclePosition,
} from './blurredCircle.types';

export const BlurredCircle = styled.div<BlurredCircleProps>`
  @media ${path(['theme', 'media', 'mobile'])} {
    display: none;
  }

  position: absolute;
  ${(props) =>
    props.position === BlurredCirclePosition.bottomRight
      ? `right: 0; bottom: 0;`
      : `left: 0; top: 50%; translate: transformY(-50%);`}
  width: 94px;
  height: 94px;
  background-color: ${(props) => props.theme.palette.blurredCircle};
  border-radius: 50%;
  filter: blur(70px);
  backdrop-filter: blur(90px);
  opacity: 0.6;
  overflow: hidden;
`;
