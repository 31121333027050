import { ActionButtonProps, ActionButtonSize } from '../action-button.types';

export const getWidth = ({ size, fullWidth, width }: ActionButtonProps) => {
  if (width) {
    return width;
  }

  if (size === ActionButtonSize.small) {
    return 110;
  }

  if (size === ActionButtonSize.navigation) {
    return 80;
  }

  if (size === ActionButtonSize.medium) {
    return 140;
  }

  if (size === ActionButtonSize.huge) {
    return 238;
  }

  // it's wide
  return 300;
};

export const getHeight = ({ size, fullWidth }: ActionButtonProps) => {
  if (size === ActionButtonSize.small) {
    return 34;
  }

  if (size === ActionButtonSize.navigation) {
    return 34;
  }

  if (size === ActionButtonSize.huge) {
    return 52;
  }

  // it's wide and medium
  return 40;
};

export const getTextSize = (
  size: ActionButtonSize,
  bolded: boolean,
  hasIcon: boolean
) => {
  const fontWeight = bolded ? 700 : 500;
  if (size === ActionButtonSize.huge) {
    return `
    font-weight: ${fontWeight};
    font-size: ${bolded ? '19px' : '16px'};
    line-height: ${bolded ? '26px' : '22px'};
    letter-spacing: 1px;
  `;
  }

  if (size === ActionButtonSize.small) {
    return `
    font-weight: ${fontWeight};
    font-size: 11px;
    line-height: 17px;
    ${hasIcon ? 'padding-left: 4px;' : ''}
  `;
  }

  if (size === ActionButtonSize.navigation) {
    return `
    font-weight: ${fontWeight};
    font-size: 10px;
    line-height: 13px;
    ${hasIcon ? 'padding-left: 4px;' : ''}
  `;
  }

  return `
    font-weight: ${fontWeight};
    font-size: 16px;
    line-height: 24px;
    ${hasIcon ? 'padding-left: 10px;' : ''}
  `;
};
