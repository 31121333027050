import { ButtonHTMLAttributes } from 'react';
import { StyledIcon } from '@styled-icons/styled-icon';

export interface ActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: ActionButtonSize;
  variant: ActionButtonVariant;
  fullWidth?: boolean;
  bolded?: boolean;
  loading?: boolean;
  IconComponent?: StyledIcon;
  iconPlacement?: ActionButtonIconPlacement;
  width?: number;
}

export enum ActionButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ActionButtonSize {
  wide = 'wide',
  medium = 'medium',
  small = 'small',
  huge = 'huge',
  navigation = 'navigation',
}

export enum ActionButtonIconPlacement {
  right = 'right',
  left = 'left',
}
