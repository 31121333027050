import React, { FC, useState, useEffect } from 'react';
import { pipe, values, filter, identity, length, propOr } from 'ramda';
import {
  ContentType,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import * as Styled from './steps-list-item.styled';
import { ProgressViewer, Expander } from '@nn-virtual-pen/education/ui';
import { theme } from '@nn-virtual-pen/ui';
import { useSpring } from '@react-spring/web';

type StepsListItemProps = ContentType[0] & {
  active: boolean;
  onListItemClick?: () => void;
};

export const StepsListItem: FC<StepsListItemProps> = ({
  active,
  Steps,
  Title,
  SubTitle,
  Index,
  onListItemClick,
}) => {
  const { steps, step, setStepAndCategory } = useUserContext();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => setExpanded(active), [active]);

  const tableStyle = useSpring({
    maxHeight: `${expanded ? (Steps.length + 1) * 65 : 0}px`,
    border: `${expanded ? 1 : 0}px solid ${theme.palette.mercuryGrey}`,
    padding: expanded ? 10 : 0,
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const thisSteps = propOr({}, `${Index}`, steps);

  const getProgressValue = pipe(values, filter(identity), length);

  const changeActiveStep = (StepIndex: number) => {
    setStepAndCategory(StepIndex, Index);
    if (onListItemClick) {
      onListItemClick();
    }
  };

  return (
    <Styled.Wrapper expanded={expanded}>
      <Styled.Category onClick={toggleExpand}>
        <Styled.LeftSide>
          <ProgressViewer
            stepsLength={Steps.length}
            value={getProgressValue(thisSteps)}
            size={40}
          />
          <Styled.TitlesWrapper>
            <Styled.Title>{Title}</Styled.Title>
            <Styled.SubTitle>{SubTitle}</Styled.SubTitle>
          </Styled.TitlesWrapper>
          <Expander expanded={expanded} />
        </Styled.LeftSide>
      </Styled.Category>
      <Styled.Steps style={tableStyle}>
        {Steps.map(({ Title, SubTitle, Index: StepIndex, LearningStep }, idx) => (
          <Styled.Step
            key={`${StepIndex}-${Title}`}
            active={active && StepIndex === step}
            onClick={() => changeActiveStep(StepIndex)}
          >
            <Styled.IconWrapper>
              {thisSteps[StepIndex] ? (
                <Styled.CheckIcon />
              ) : (
                <Styled.UncheckIcon />
              )}
              {Steps.length - 1 > idx && (
                <Styled.Line active={thisSteps[StepIndex]} />
              )}
            </Styled.IconWrapper>
            <Styled.TitlesWrapper>
              <Styled.Title>{LearningStep} {Title}</Styled.Title>
              <Styled.SubTitle>{SubTitle}</Styled.SubTitle>
            </Styled.TitlesWrapper>
          </Styled.Step>
        ))}
      </Styled.Steps>
    </Styled.Wrapper>
  );
};
