import styled from 'styled-components';
import { path } from 'ramda'
import { a } from '@react-spring/web';
import { Link as RouterLink } from '@reach/router';

export const MenuList = styled(a.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 65px;
  right: 15px;
  color: ${path(['theme', 'palette', 'nnBlue'])};
  min-width: 80px;
  overflow: hidden;
  z-index: ${path(['theme', 'zIndex', 'mobileMenu'])};
`

export const MenuRouterLink = styled(RouterLink)`
  margin-top: 6px;
`

export const MenuListItem = styled.div`
  margin-top: 6px;
`
