import styled from 'styled-components';
import { path } from 'ramda';

export const Buttons = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid ${path(['theme', 'palette', 'disabledBg'])};
`;
