import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface EducationUiProps {}

const StyledEducationUi = styled.div`
  color: pink;
`;

export const EducationUi = (props: EducationUiProps) => {
  return (
    <StyledEducationUi>
      <h1>Welcome to education-ui!</h1>
    </StyledEducationUi>
  );
};

export default EducationUi;
