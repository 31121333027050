import React, { FC } from 'react';
import { Menu, Blanket, ContentWrapper } from '../drop-down.styled';
import { ContentProps } from '../drop-down.types';

export const Content: FC<ContentProps> = ({
  children,
  isOpen,
  target,
  onClose,
  width,
  fullWidth,
}) => (
  <ContentWrapper fullWidth={fullWidth} width={width}>
    {target}
    {isOpen && (
      <>
        <Menu fullWidth={fullWidth}>{children}</Menu>
        <Blanket onClick={onClose} />
      </>
    )}
  </ContentWrapper>
);
