import styled from 'styled-components';
import { prop } from 'ramda';

export const Wrapper = styled.div<Pick<HTMLDivElement, 'align'>>`
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: ${props => props.theme.palette.nnBlue};
  margin-bottom: 10px;
  text-align: ${prop('align') || 'center'};
`;
