export enum AuthContextActionType {
  reAuthenticate = 'reAuthenticate',
  startAuthentication = 'startAuthentication',
  authenticatePassCode = 'authenticatePassCode',
  authenticateToken = 'authenticateToken',
  unauthenticated = 'unauthenticated',
  signOut = 'signOut',
}

export interface AuthContextState {
  isLoading: boolean;
  token: string;
}

export type AuthContextAction =
  | { type: AuthContextActionType.startAuthentication }
  | { type: AuthContextActionType.authenticateToken; token: string; }
  | { type: AuthContextActionType.authenticatePassCode; token: string; }
  | { type: AuthContextActionType.reAuthenticate; token: string; }
  | { type: AuthContextActionType.unauthenticated }
  | { type: AuthContextActionType.signOut };

export interface AuthContextActions {
  reAuthenticate: () => Promise<void>;
  authenticatePassCode: (passCode: string) => Promise<void>;
  authenticateToken: (token: string) => Promise<void>;
  unauthenticated: () => void;
  signOut: () => void;
}

export interface AuthContextProps {
  state: AuthContextState;
  actions: AuthContextActions;
}
