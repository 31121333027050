import React, {
  FC,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth-context.consts';
import { EventMessage, EventType } from '@azure/msal-browser';
import useLocalStorageState from 'use-local-storage-state';

export const AuthContext = createContext<{ accessToken?: string }>({});

export const AuthProvider: FC = ({ children }) => {
  const instance = useMemo(() => new PublicClientApplication(msalConfig), []);
  const [accessToken, setAccessToken] = useLocalStorageState('adminToken');

  useEffect(() => {
    const callbackId = instance.addEventCallback((response: EventMessage) => {
      if (response.eventType === EventType.LOGIN_SUCCESS) {
        setAccessToken((response.payload as any).accessToken);
      }

      if (response.eventType === EventType.LOGOUT_SUCCESS) {
        setAccessToken.reset();
      }

      if (
        response.eventType === EventType.LOGIN_FAILURE &&
        response?.error.message.includes('AADB2C90118')
      ) {
        instance.loginRedirect({
          prompt: 'login',
          scopes: [process.env.NX_ADMIN_PANEL_B2C_API_SCOPE, 'openid'],
          authority: process.env.NX_ADMIN_PANEL_B2C_CHANGE_PASSWORD_AUTHORITY,
        });
        return;
      }

      if (response.eventType === EventType.LOGIN_FAILURE) {
        console.error(response);
      }
    });

    return () => instance.removeEventCallback(callbackId);
  }, [instance]);

  return (
    <MsalProvider instance={instance}>
      <AuthContext.Provider value={{ accessToken }}>
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
