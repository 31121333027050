import React, { FC, Dispatch, SetStateAction } from 'react';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import { GlassesAlt } from 'styled-icons/boxicons-regular';
import { Slider } from '../slider';
import * as Styled from './content.styled';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

interface ContentProps {
  readPages: string;
  isReadMore: boolean;
  setReadMore: Dispatch<SetStateAction<boolean>>;
}

export const Content: FC<ContentProps> = ({
  readPages,
  isReadMore,
  setReadMore,
}) => {
  const { t } = useTranslate();

  if (isReadMore) {
    return (
      <Styled.Wrapper>
        <Styled.TransparencyWrapper>
          <Styled.Transparency />
          <Styled.AnimatedContent
            dangerouslySetInnerHTML={{ __html: readPages }}
          />
        </Styled.TransparencyWrapper>
        <Styled.ButtonWrapper>
          <ActionButton
            onClick={() => setReadMore(false)}
            IconComponent={GlassesAlt}
            size={ActionButtonSize.small}
            variant={ActionButtonVariant.secondary}
          >
            {t(TranslationKey.read_more)}
          </ActionButton>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
    );
  }


  return (
    <Styled.Wrapper>
      <Styled.AnimatedContent
        dangerouslySetInnerHTML={{ __html: readPages }}
      />
    </Styled.Wrapper>
  );
};
