import React from 'react';
import { ErrorMessage as BaseErrorMessage } from '@hookform/error-message';
import * as Styled from './error-message.styled';

export const ErrorMessage = ({ hideError, errors, name }) => {
  if (hideError) {
    return null;
  }

  return (
    <BaseErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Styled.Wrapper>
            <Styled.AlertIcon />
            <Styled.Text>{message}</Styled.Text>
        </Styled.Wrapper>
      )}
    />
  );
};
