import { LoadingIndicator } from '../../loading-indicator';
import React from 'react';

export const getTextInfo = (
  isLoading: boolean,
  textValue: string,
  placeholder?: string
) => {
  if (isLoading) {
    return (
      <LoadingIndicator align="center" />
    );
  }

  return textValue || placeholder;
};
