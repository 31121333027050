import styled from 'styled-components';
import { prop, path } from 'ramda'
import { PlayCircle, StopCircle } from 'styled-icons/fa-regular';
import { Sync } from 'styled-icons/octicons';

export const Wrapper = styled.button.attrs({ type: 'button' })<{ size: number }>`
  width: ${prop('size')}px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
`;

export const PlayIcon = styled(PlayCircle)`
  color: ${path(['theme', 'palette', 'nnBlue'])};
`

export const StopIcon = styled(StopCircle)`
  color: ${path(['theme', 'palette', 'nnBlue'])};
`

export const ReloadIcon = styled(Sync)`
  color: ${path(['theme', 'palette', 'nnBlue'])};
`
