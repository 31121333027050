import React, { useMemo, useRef, useEffect } from 'react';
import { values, propOr } from 'ramda';
import { useThree } from 'react-three-fiber';
import { useGLTF } from '@react-three/drei/useGLTF';
import { OrbitControls } from 'drei';
import { useProductMaterials } from './hooks/useProductMaterials';
import { Material, Mesh, MeshStandardMaterial, Texture, TextureLoader } from 'three';
import { useAnimation } from './hooks/useAnimation';
import { useFitCameraToSelection } from './hooks/useFitCameraToSelection';

export const Model = ({
  path,
  productMaterials,
  productMaterialKey,
  animationConfig,
  animationState,
  onAnimationLoaded,
  onAnimationStart,
  onAnimationStop,
  setProgress,
  onGestureCompleted,
  allowRotation = false,
  product,
}) => {
  const gltf = useGLTF(path, true) as any;

  useEffect(() => {
    console.info(
      '[Animations]',
      gltf.animations.map(
        ({ name, duration }) => `${name} - duration: ${duration}`
      )
    );
    console.info(
      '[Model parts]',
      values(gltf.nodes).reduce(
        (acc, { name, type }) => (type === 'Mesh' ? [...acc, name] : acc),
        []
      )
    );
  }, []);

  const group = useRef(null);
  const controls = useRef(null);
  const { camera } = useThree();

  const productMaterial: MeshStandardMaterial = useProductMaterials(
    gltf,
    productMaterialKey,
    Object.values(productMaterials)
  );

  const saxendaDoseTexture = useMemo(() => {
    const texture = new TextureLoader().load('/models/FlexTouch/0-saxenda-dose.png')
    texture.flipY = false;
    return texture;
  }, [])

  const scene = useMemo(() => {
    const newScene = gltf.scene.clone(true);
    newScene.traverse((object: Mesh) => {
      if ((propOr([], 'hiddenParts', animationConfig) as string[]).includes(object.name)) {
        object.visible = false;
      } else {
        object.visible = true;
      }
      if (productMaterial && object.type === 'Mesh') {
        if (values(productMaterials || {}).includes((object.material as Material & { name?: string })?.name)) {
          object.material = productMaterial;
        }

        if (object.name === 'Dose_label' && product === 'Saxenda') {
          (object.material as Material & { map: Texture }).map = saxendaDoseTexture;
        }
      }
    });
    return newScene;
  }, [gltf, group.current, animationConfig.name, productMaterialKey, productMaterial]);

  useAnimation({
    animationConfig,
    animationState,
    animations: gltf.animations,
    root: group.current,
    onAnimationStop,
    onAnimationStart,
    onAnimationLoaded,
    setProgress,
    onGestureCompleted,
    productMaterial,
    allowRotation,
  });

  useFitCameraToSelection(
    camera,
    controls.current,
    gltf.scenes,
    animationConfig.name,
    2,
    allowRotation ? 0.8 : 1.1
  );

  return (
    <>
      <OrbitControls
        enableZoom={allowRotation}
        enableRotate={allowRotation}
        ref={controls}
      />
      <group ref={group}>
        <primitive object={scene} />
      </group>
    </>
  );
}
