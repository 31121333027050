import React, { FC } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { theme } from '@nn-virtual-pen/ui';
import * as Styled from './progress-viewer.styled'

interface Props {
  stepsLength: number,
  value: number,
  size?: number,
  text?: string,
}

export const ProgressViewer: FC<Props> = ({ stepsLength, value, size, text }) => (
  <Styled.Wrapper size={size}>
    <CircularProgressbar
      value={(value / stepsLength) * 100}
      strokeWidth={4}
      text={text || `${value}/${stepsLength}`}
      background
      styles={buildStyles({
        pathColor: theme.palette.justGreen,
        trailColor: 'white',
        backgroundColor: theme.palette.selago,
        textColor: 'black'
      })}
    />
  </Styled.Wrapper>
);
