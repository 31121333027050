import React, { FC } from 'react';
import Tippy from '@tippy.js/react';
import {
  Wrapper,
  Content,
  ValueWrapper,
  ChevronWrapper,
} from '../drop-down.styled';
import { TriggerProps } from '../drop-down.types';

export const Trigger: FC<TriggerProps> = ({
  iconAfter,
  onClick,
  hasValue,
  width,
  isLoading,
  error,
  disabled,
  disabledText,
  isMenuOpen,
  children,
}) => {
  return (
    <Tippy
      placement="top"
      theme="light-border"
      content={disabledText || ''}
      enabled={!!disabled}
    >
      <Wrapper
        type="button"
        hasValue={hasValue}
        error={error}
        disabled={disabled || isLoading}
        isMenuOpen={isMenuOpen}
        onClick={onClick}
        width={width}
      >
        <Content>
          <ValueWrapper isLoading={isLoading}>{children}</ValueWrapper>
          <ChevronWrapper>{iconAfter}</ChevronWrapper>
        </Content>
      </Wrapper>
    </Tippy>
  );
};
