import styled from 'styled-components';
import { prop, path } from 'ramda'

export const Header = styled.div<{ headerHeight: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${prop('headerHeight')}px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 3px;
`

export const SubTitle = styled.div`
  font-size: 10px;
  color: ${path(['theme', 'palette', 'scorpionGrey'])};
`
