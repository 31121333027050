import React, { FC } from 'react';
import { path, prop } from 'ramda';
import styled from 'styled-components';
import {
  useUserContext,
  useContentContext,
} from '@nn-virtual-pen/education/data-access';
import { HiddenHelper } from '@nn-virtual-pen/education/ui';
import { StepsListItem } from './steps-list-item';

export interface StepsListProps {
  height?: string;
  width?: string;
  hidden?: boolean;
  onListItemClick?: () => void;
  visible: boolean;
}

const StyledStepsList = styled.div<{ height: string; width: string; visible: boolean; }>`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  width: 100%;
  max-width: ${prop('width')};
  background-color: ${path(['theme', 'palette', 'white'])};
  min-height: ${prop('height')};
  margin: 0 auto;
  overflow: auto;
`;

export const StepsList: FC<StepsListProps> = ({
  height = '100%',
  width = '100%',
  hidden = false,
  onListItemClick,
  visible,
}) => {
  const { category } = useUserContext();
  const { content } = useContentContext();

  return (
    <StyledStepsList visible={visible} height={height} width={width}>
      <HiddenHelper hidden={hidden}>
        {content.map((step, idx) => (
          <StepsListItem
            {...step}
            key={step.Index}
            active={category === step.Index}
            onListItemClick={onListItemClick}
          />
        ))}
      </HiddenHelper>
    </StyledStepsList>
  );
};

export default StepsList;
