import styled from 'styled-components';

export const DesktopLayout = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const StepsWrapper = styled.div`
  flex: 1;
  padding: 0 15px;
  height: 100%;
  overflow: auto;
`;

export const ModelViewer = styled.div`
  flex: 2;
`;

export const InfoComponent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div`
  padding: 0 15px;
  height: calc(100% - 100px);
`;
