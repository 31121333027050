import styled from 'styled-components';
import { DotProps, LoadingIndicatorProps } from './loading-indicator.types';

export const Wrapper = styled.div<LoadingIndicatorProps>`
    position: relative;
    font-size: 6px;
    width: 100%;
    text-align: ${props => props.align};
`;

export const Dot = styled.span<DotProps>`
    animation: 1s ease-in-out ${props => props.delay}ms infinite normal none running loading-indicator;
    background-color: currentcolor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    width: 1em;

    :not(:last-of-type) {
      margin-right: 1em;
    }
`;
