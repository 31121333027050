import styled, { css } from 'styled-components';
import { path } from 'ramda';

export const Wrapper = styled.header<{ displayOnMobile: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${path(['theme', 'palette', 'white'])};
  border-bottom: 1px solid ${path(['theme', 'palette', 'mystic'])};
  z-index: ${path(['theme', 'zIndex', 'header'])};

  @media ${(props) => props.theme.media.tablet} {
    display: block;
  }

  ${props => props.displayOnMobile && css`
    @media ${props.theme.media.mobile} {
      display: block;
    }
  `}
`;

export const NegativeMargin = styled.div`
  display: none;

  @media ${(props) => props.theme.media.tablet} {
    display: block;
    height: ${(props) => props.theme.header.offset}px;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;

  @media ${(props) => props.theme.media.tablet} {
    height: ${(props) => props.theme.header.offset}px;
    padding: 0;
  }
`;
