// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-configure-tsx": () => import("./../../../src/pages/configure.tsx" /* webpackChunkName: "component---src-pages-configure-tsx" */),
  "component---src-pages-congratulations-tsx": () => import("./../../../src/pages/congratulations.tsx" /* webpackChunkName: "component---src-pages-congratulations-tsx" */),
  "component---src-pages-exceed-limit-tsx": () => import("./../../../src/pages/exceed-limit.tsx" /* webpackChunkName: "component---src-pages-exceed-limit-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-tsx": () => import("./../../../src/pages/learning.tsx" /* webpackChunkName: "component---src-pages-learning-tsx" */),
  "component---src-pages-terms-of-condition-tsx": () => import("./../../../src/pages/terms-of-condition.tsx" /* webpackChunkName: "component---src-pages-terms-of-condition-tsx" */)
}

