import React, { FC, useState } from 'react';
import Select, {NamedProps } from 'react-select';
import { customStyles, getTextInfo } from './helpers';
import { Trigger } from './trigger';
import { ChevronDown } from './chevron-down';
import { Content } from './content';
import { DropdownIndicator } from './dropdown-indicator';
import { DropDownProps, DropDownValue } from './drop-down.types';

export const DropDown: FC<DropDownProps> = ({
  name,
  value,
  onChange,
  options,
  width,
  fullWidth,
  placeholder,
  isLoading = false,
  disabled = false,
  error,
  disabledText,
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const onSelectChange: NamedProps<DropDownValue>['onChange'] = ({ value }) => {
    setOpen(false);
    onChange(value);
  };


  const v = options?.find(o => o.value === value);

  return (
    <Content
      width={width}
      fullWidth={fullWidth}
      isOpen={disabled ? false : isOpen}
      onClose={toggleOpen}
      target={
        <Trigger
          iconAfter={<ChevronDown />}
          {...(!disabled && !isLoading && { onClick: toggleOpen })}
          isMenuOpen={isOpen}
          disabled={disabled}
          error={error}
          isLoading={isLoading}
          disabledText={disabledText}
          hasValue={!!value}
          width={width}
        >
          {getTextInfo(isLoading, v?.label, placeholder)}
        </Trigger>
      }
    >
      <Select
        autoFocus
        menuIsOpen
        openMenuOnFocus
        isSearchable={false}
        backspaceRemovesValue={false}
        name={name}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        isOptionSelected={(option) => option?.value === value}
        onChange={onSelectChange}
        options={options}
        placeholder="Search..."
        styles={customStyles}
        loadingMessage={() => 'Loading...'}
        noOptionsMessage={() => 'No records'}
      />
    </Content>
  );
};
