module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Virtual Pen","short_name":"Virtual Pen","start_url":"/","background_color":"#001965","theme_color":"#f2fbff","display":"standalone","icon":"public/favicon/favicon-196.png","icons":[{"src":"public/favicon/manifest-icon-192.png","sizes":"192x192","type":"image/png"},{"src":"public/favicon/manifest-icon-512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/home/vsts/work/1/s/VirtualPen.App/apps/education/src/service-worker.js","workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"G-D18TV3TZB1","autoStart":false,"anonymize":true,"controlCookieName":"gatsby-gdpr-google-analytics","cookieFlags":"secure;samesite=none"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
