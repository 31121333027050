import browserLang from 'browser-lang';
import { getIsBrowser } from '@nn-virtual-pen/utils';

export const getUserLanguage = () => {
  if (!getIsBrowser()) {
    return 'pt';
  }

  // todo: in the future - fetch supported languages
  return browserLang({
    languages: ['pt', 'es'],
    fallback: 'pt',
  });
};
