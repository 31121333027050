import React from 'react';
import {
  getRoutesConfig,
  Routes,
  useSupportContext,
} from '@nn-virtual-pen/education/utils';
import { useTranslate } from '@nn-virtual-pen/education/data-access';
import { NavLink } from '@nn-virtual-pen/ui';
import { Link as RouterLink } from '@reach/router';

export const Navigation = () => {
  const { t } = useTranslate();
  const config = getRoutesConfig();
  const { setSupportOpen } = useSupportContext();

  return (
    <nav role="navigation">
      <ul>
        {config.map(({ route, name }) => {
          return (
            <NavLink key={`${route}-${name}`}>
              {name === Routes.Support ? (
                <div onClick={() => setSupportOpen(true)}>
                  {t(name)}
                </div>
              ) : (
                <RouterLink to={route}>{t(name)}</RouterLink>
              )}
            </NavLink>
          );
        })}
      </ul>
    </nav>
  );
};
