import React from 'react';
import { useParams } from '@reach/router';
import * as Styled from './debug.styled';
import packageJson from '../../../../../package.json';
import { getIsBrowser } from '@nn-virtual-pen/utils';

export const Debug = () => {
  if (!getIsBrowser()) {
    return null;
  }

  if (window.location.search !== '?debug=true') {
    return null;
  }

  return (
      <Styled.Wrapper>
        <strong>Debug</strong>
        <span>v {packageJson.version}</span>
        <span>ls {JSON.stringify(localStorage, null, 2)}</span>
      </Styled.Wrapper>
  );
};
