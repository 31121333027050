import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface EducationUtilsProps {}

const StyledEducationUtils = styled.div`
  color: pink;
`;

export const EducationUtils = (props: EducationUtilsProps) => {
  return (
    <StyledEducationUtils>
      <h1>Welcome to education-utils!</h1>
    </StyledEducationUtils>
  );
};

export default EducationUtils;
