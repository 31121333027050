import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import Lottie from 'react-lottie-player';
import substepComplete from 'static/animations/substep-complete.json';
import * as Styled from './model-animations-context.styled';

interface ModelAnimationsContextType {
  onGestureCompleted: () => void;
  setAnimationComplete: Dispatch<SetStateAction<boolean>>;
  animationIndex: number;
  setAnimationIndex: Dispatch<SetStateAction<number>>;
  animationComplete: boolean;
  subStepComplete: boolean;
  animationCompleteRef;
  setSubStepComplete;
  resetAnimationIndex;
}

const CreateModelAnimationsContext = createContext<ModelAnimationsContextType>(
  undefined
);

export const ModelAnimationsContext = ({ children }) => {
  const animationCompleteRef = useRef(false); 
  const [animationComplete, setInnerAnimationComplete] = useState(false);
  const [subStepComplete, setSubStepComplete] = useState(false);
  const [displayLottie, setDisplayLottie] = useState(false);
  const [animationIndex, setAnimationIndex] = useState(0);

  const resetAnimationIndex = () => {
    setSubStepComplete(false);
    setAnimationIndex(0);
  };

  const onGestureCompleted = () => {
    setDisplayLottie(true);
  };

  const onLottieComplete = () => {
    setDisplayLottie(false);
    setSubStepComplete(true);
  };

  const setAnimationComplete = value => {
    animationCompleteRef.current = value;
    setInnerAnimationComplete(value)
  }

  const LottieAnimation = useMemo(
    () =>
      displayLottie && (
        <Styled.LottieWrapper>
          <Lottie
            animationData={substepComplete}
            play
            loop={false}
            onComplete={onLottieComplete}
          />
        </Styled.LottieWrapper>
      ),
    [displayLottie]
  );

  return (
    <CreateModelAnimationsContext.Provider
      value={{
        onGestureCompleted,
        setAnimationComplete,
        animationIndex,
        setAnimationIndex,
        animationComplete,
        animationCompleteRef,
        subStepComplete,
        setSubStepComplete,
        resetAnimationIndex,
      }}
    >
      {LottieAnimation}
      {children}
    </CreateModelAnimationsContext.Provider>
  );
};

export const useModelAnimationsContext = () =>
  useContext(CreateModelAnimationsContext);
