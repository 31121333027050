import React, { FC } from 'react';
import * as Styled from './loading-indicator.styled'
import { LoadingIndicatorProps } from './loading-indicator.types';

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ align }) => {
  return (
    <Styled.Wrapper align={align}>
      <Styled.Dot delay={0} />
      <Styled.Dot delay={160} />
      <Styled.Dot delay={320} />
    </Styled.Wrapper>
  )
}
