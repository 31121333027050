import { useReducer, useRef, useCallback } from 'react';
import { initialState, reducer } from './reducer';
import { useMount } from 'react-use';
import {
  AuthContextActions,
  AuthContextActionType,
} from './auth-context.types';
import useLocalStorageState from 'use-local-storage-state';
import { useApi as useAdminApi } from '@nn-virtual-pen/admin-panel/data-access';
import { useUserContext } from '../user-context/user-context';
import {
  Device,
  FlexPenProduct,
  FlexTouchProduct,
} from '../user-context/user-context.types';
import { getDevice } from '@nn-virtual-pen/utils';
import { navigate } from 'gatsby';
import { RoutePath } from '@nn-virtual-pen/education/utils';

export const useAuthContext = () => {
  const { api } = useAdminApi(true);
  const { setConfiguration, configuration } = useUserContext();
  const [token, setToken] = useLocalStorageState<string>('token', '');
  const [code, setCode] = useLocalStorageState<string>('code', '');

  const [state, dispatch] = useReducer(reducer, initialState);

  const failureCallback = useCallback(
    (
      type:
        | AuthContextActionType.signOut
        | AuthContextActionType.unauthenticated
    ) => {
      setToken('');
      dispatch({ type });
    },
    [setToken]
  );

  const { current: actions } = useRef<AuthContextActions>({
    reAuthenticate: async (): Promise<void> => {
      if (!token && !code) {
        return;
      }

      dispatch({
        type: AuthContextActionType.reAuthenticate,
        token: token || code,
      });
    },
    authenticateToken: async (newToken): Promise<void> => {
      try {
        dispatch({
          type: AuthContextActionType.startAuthentication,
        });

        if (token !== newToken) {
          const result = await api.redeemCodeCreate({ token: newToken });
          const { country, pen } = result.data;

          setToken(newToken);
          setConfiguration({
            ...configuration,
            country,
            product: pen as FlexTouchProduct | FlexPenProduct,
            device: getDevice(pen) as Device,
          });
        }

        dispatch({
          type: AuthContextActionType.authenticateToken,
          token: newToken,
        });
      } catch (error) {
        failureCallback(AuthContextActionType.unauthenticated);
        void navigate(RoutePath.ExceedLimit);
        throw error;
      }
    },
    authenticatePassCode: async (passCode): Promise<void> => {
      try {
        await api.codeCreate({ code: Number.parseInt(passCode) })

        setCode(passCode);
        setConfiguration({
          ...configuration,
          country: '',
          product: '',
          device: '',
        });
        dispatch({
          type: AuthContextActionType.authenticatePassCode,
          token: passCode,
        });
      } catch (error) {
        return Promise.reject('invalid');
      }
    },
    unauthenticated: () => {
      dispatch({ type: AuthContextActionType.unauthenticated });
    },
    signOut: () => {
      failureCallback(AuthContextActionType.signOut);
    },
  });

  useMount(() => {
    if (!token && !code) {
      return void actions.unauthenticated();
    }

    void actions.reAuthenticate();
  });

  return {
    state,
    actions,
  };
};
