import styled, { css } from 'styled-components';
import { path } from 'ramda';

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const Check = styled.div<{ checked: boolean; error: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) =>
    checked ? theme.palette.seaBlue : theme.palette.white};
  border-radius: 3px;
  ${({ checked, theme, error }) =>
    !checked &&
    css`
      border: 2px solid
        ${error ? theme.palette.error : theme.palette.graniteGrey};
    `}
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  color: ${path(['theme', 'palette', 'jumbo'])};
  font-size: 13px;
`;

export const Label = styled.span`
  margin-left: 10px;
`;
