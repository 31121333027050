import React, { FC } from 'react';
import * as Styled from './substeps-indicator.styled';

interface SubstepsIndicatorProps {
  activeIndex: number;
  substepsLength: number;
}

export const SubstepsIndicator: FC<SubstepsIndicatorProps> = ({
  activeIndex,
  substepsLength,
}) => {
  if (substepsLength < 2) return null;
  return (
    <Styled.IndicatorPack>
      {Array(substepsLength)
        .fill(null)
        .map((v, idx) => (
          <React.Fragment key={`${v}-${idx}`}>
            <Styled.Dot>
              {activeIndex === idx && <Styled.ActiveDot />}
            </Styled.Dot>
            {substepsLength - 1 > idx && <Styled.Line />}
          </React.Fragment>
        ))}
    </Styled.IndicatorPack>
  );
};
