import React from 'react';
import { AnimationState } from './animation-control.types';
import * as Styled from './animation-control.styled';

interface Props {
  action: AnimationState
  size: number
  onClick?: () => void
}

export const AnimationControl = ({ action, size, onClick }: Props) => {
  const Icon = {
    play: Styled.PlayIcon,
    playing: Styled.StopIcon,
    canceling: Styled.StopIcon,
    completed: Styled.ReloadIcon,
    resetting: Styled.ReloadIcon,
  }[action];

  return (
    <Styled.Wrapper size={size} onClick={onClick}>
      <Icon />
    </Styled.Wrapper>
  )
}
