import styled from 'styled-components';
import { path } from 'ramda';
import { a } from '@react-spring/web';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  font-weight: ${path(['theme', 'fontWeight', 'regular'])};
  font-size: 14px;
  line-height: 24px;
`;

export const TransparencyWrapper = styled.div`
  max-height: 172px;
  position: relative;
  overflow: hidden;
`;

export const Transparency = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 60px 0;
  background-image: linear-gradient(to bottom, transparent, white);
`;

export const AnimatedContent = styled(a.div)`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  z-index: 2;
`;

export const LottieWrapper = styled.div`
  position: absolute;
  top: 25%;
  z-index: 1;
`