import styled from 'styled-components';
import { propOr } from 'ramda';

export const Wrapper = styled.div<{ visible: boolean; }>`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  margin-top: 30px;
  margin-right: 15px;
  width: 15px;
`;
