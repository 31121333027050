import styled from 'styled-components';
import { path } from 'ramda';

interface ContainerProps {
  readonly isFluid?: boolean;
  readonly stretchHeight?: boolean;
}

const fluidStyles = 'max-width: none; padding-left: 32px; padding-right: 32px;';

export const Container = styled.div<ContainerProps>`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  ${(props) => props.stretchHeight && `height: 100%;`}

  @media ${path(['theme', 'media', 'tablet'])} {

    ${(props) =>
      props.isFluid
        ? fluidStyles
        : `max-width: ${
            props.theme.breakpoints.tablet - props.theme.container.offset
          }px;`}
  }

  @media ${path(['theme', 'media', 'desktop'])} {
    padding-left: unset;
    padding-right: unset;
    ${(props) =>
      props.isFluid
        ? fluidStyles
        : `max-width: ${
            props.theme.breakpoints.desktop - props.theme.container.offset
          }px;`}
  }
`;
