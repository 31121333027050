export type ContentType = Array<{
  Index: number;
  SubTitle: string;
  Title: string;
  Steps: Array<{
    Index: number;
    LearningStep: number;
    Title: string;
    SubTitle: string;
    VoiceText: string;
    ReadPages: string[];
    ImportantPage: string;
    CompleteStepModal: string;
    SubSteps: Array<{
      InformationModal: string;
      HiddenParts: string[];
      AnimationStartAt: number;
      AnimationFinishAt: number | 'None';
      AnimationName: string;
      AnimationGesture: GestureAnimation;
      ModelRotation: number;
    }>;
  }>;
}>;

export type ContentStepType = ContentType[0]['Steps'][0];

export enum GestureAnimation {
  DragUp = 'DragUp',
  DragDown = 'DragDown',
  TapClick = 'TapClick',
  TapHold = 'TapHold',
  SlideLeft = 'SlideLeft',
  SlideRight = 'SlideRight'
}
