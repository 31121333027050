import { pipe, find, propEq, propOr, prop, dec, inc, last, path } from 'ramda';
import { ContentType, ContentStepType } from './content-context.types';

export const getLastStepOfPreviousCategory = (content, activeCategory) =>
  pipe(
    find(propEq('Index', dec(activeCategory.Index))),
    prop('Steps') as any,
    last,
    prop('Index')
  )(content) as number;

export const getFirstStepOfNextCategory = (content, activeCategory) =>
  pipe(
    find(propEq('Index', inc(activeCategory.Index))),
    path(['Steps', '0', 'Index'])
  )(content) as number;

export const addFlagsToSteps = (collection: ContentType[0]) =>
  collection && {
    ...collection,
    Steps: (propOr([], 'Steps', collection) as ContentStepType[]).map((step, idx) => ({
      ...step,
      isFirst: idx === 0,
      isLast: dec(collection.Steps.length) === idx,
    })),
  };

export const addFlagsToCategories = (collection) =>
  collection &&
  collection.map((category, idx) => ({
    ...category,
    isFirst: idx === 0,
    isLast: dec(collection.length) === idx,
  }));

export const getFirstIncompletedStepAndCategory = (steps, content) => {
  for (let i = 0; i < content.length; i++) {
    const { Steps, Index } = content[i];
    const foundStep = Steps.find(({ Index: stepIdx }) =>
      !steps[Index] || !steps[Index][stepIdx]
    );

    if (foundStep) {
      return {
        category: Index,
        step: foundStep.Index,
      }
    }
  }
}