import React, { FC, useState } from 'react';
import { Header } from './header';
import { Content } from './content';
import * as Styled from './step-information.styled';
import { StepInformationProps } from './step-information.types';
import { Close } from 'styled-icons/zondicons';
import { useContentContext, useUserContext } from '@nn-virtual-pen/education/data-access';
import { useDidUpdateEffect } from '@nn-virtual-pen/education/utils';

export const StepInformation: FC<StepInformationProps> = ({
  defaultReadMore = false,
  onClose,
  visible,
}) => {
  const { step } = useUserContext();
  const { activeStep } = useContentContext();
  const [isReadMore, setReadMore] = useState<boolean>(defaultReadMore ?? true);

  useDidUpdateEffect(() => {
    setReadMore(true);
  }, [step]);

  const close = onClose || (() => setReadMore(true));

  return (
    <Styled.Wrapper visible={visible}>
      <Styled.HeaderWrapper>
        <Header step={activeStep.LearningStep}>{activeStep.Title}</Header>
        {(onClose || !isReadMore) && (
          <Styled.CloseButton onClick={close}>
            <Close />
          </Styled.CloseButton>
        )}
      </Styled.HeaderWrapper>
      <Content
        readPages={activeStep.ReadPages}
        isReadMore={isReadMore}
        setReadMore={setReadMore}
      />
    </Styled.Wrapper>
  );
};
