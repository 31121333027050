import styled from 'styled-components';
import { prop, path } from 'ramda'
import { GlassesAlt } from 'styled-icons/boxicons-regular';
import { ArrowBack } from 'styled-icons/evaicons-solid';

export const Footer = styled.div<{ height: number }>`
  height: ${prop('height')}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const ReadMoreButton = styled(GlassesAlt)`
  width: 24px;
  margin: 14px;
  color: ${path(['theme', 'palette', 'nnBlue'])};
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
`;

export const BackButton = styled(ArrowBack)`
  width: 24px;
  margin: 14px;
  color: ${path(['theme', 'palette', 'nnBlue'])};
`;
