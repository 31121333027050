import React from 'react';
import { path } from 'ramda';
import * as Styled from './step-buttons.styled';
import {
  ActionButton,
  ActionButtonVariant,
  ActionButtonSize,
  ActionButtonIconPlacement,
} from '@nn-virtual-pen/ui';
import {
  TranslationKey,
  useContentContext,
  useTranslate,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { useStepModalContext } from '../../step-modal-context';
import { NavigateNext, NavigateBefore } from 'styled-icons/material';

export const StepButtons = () => {
  const { t } = useTranslate();
  const { setStepModalOpen } = useStepModalContext();
  const { steps } = useUserContext();
  const {
    activeStep,
    activeCategory,
    setPreviousStep,
    setNextStep,
  } = useContentContext();

  const onNextClick = () => {
    if (path([activeCategory.Index, activeStep.Index], steps)) {
      setNextStep();
    } else {
      setStepModalOpen(true);
    }
  };

  return (
    <Styled.Buttons>
      <ActionButton
        variant={ActionButtonVariant.secondary}
        size={ActionButtonSize.small}
        onClick={setPreviousStep}
        IconComponent={NavigateBefore}
      >
        {t(TranslationKey.prev_step_button)}
      </ActionButton>
      <ActionButton
        variant={ActionButtonVariant.primary}
        size={ActionButtonSize.small}
        onClick={onNextClick}
        IconComponent={NavigateNext}
        iconPlacement={ActionButtonIconPlacement.right}
      >
        {t(TranslationKey.next_step_button)}
      </ActionButton>
    </Styled.Buttons>
  );
};
