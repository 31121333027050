import React, { FC } from 'react';
import { useSpring } from '@react-spring/core';
import { a } from '@react-spring/web';

export interface HiddenHelperProps {
  hidden: boolean;
  onClick?: () => void;
  initiallyHidden?: boolean;
}

export const HiddenHelper: FC<HiddenHelperProps> = ({
  children,
  hidden,
  onClick,
  initiallyHidden,
}) => {
  const props = useSpring({
    opacity: hidden ? 0 : 1,
    from: { opacity: initiallyHidden ? 1 : 0 },
  });
  return (
    <a.div onClick={onClick} style={props}>
      {children}
    </a.div>
  );
};

export default HiddenHelper;
