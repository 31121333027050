import { useCallback } from 'react';
import { Schema } from 'yup';

export const getIsBrowser = (): boolean =>
  typeof window !== 'undefined' || typeof navigator !== 'undefined';

export const useYupValidationResolver = <T>(validationSchema: Schema<T>) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export const fieldOptionsMap = (value: string) => ({
  value,
  label: value,
});

export const getDevice = (pen) => {
  // TODO: fast fix, get it from the right source
  if (['Ozempic', 'Saxenda', 'Tresiba', 'Xultophy'].includes(pen)) {
    return 'FlexTouch';
  }
  if (['Levemir', 'NovoRapid', 'Victoza'].includes(pen)) {
    return 'FlexPen';
  }
};
