import React, { FC } from 'react';
import * as Styled from './label.styled';

export const Label: FC<Pick<HTMLDivElement, 'align'>> = ({ children, align }) => {
  return (
    <Styled.Wrapper align={align}>{children}</Styled.Wrapper>
  );
};

export default Label;
