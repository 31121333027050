import styled from 'styled-components';
import { Info } from '@styled-icons/material-twotone/Info';
import { path } from 'ramda';

export const AlertIcon = styled(Info)`
  transform: rotate(180deg);
  height: 14px;
`;

export const Wrapper = styled.div`
  padding-top: 5px;
  height: 30px;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: ${path(['theme', 'palette', 'error'])};
  display: flex;
  align-items: center;
`;

export const Text = styled.div`margin-left: 5px;`;
