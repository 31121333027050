import React, { FC, useRef, useEffect } from 'react';
import { inc, path } from 'ramda';
import { HiddenHelper } from '@nn-virtual-pen/education/ui';
import { useDidUpdateEffect } from '@nn-virtual-pen/education/utils';
import dragDownGesture from 'static/animations/DragDown.json';
import dragUpGesture from 'static/animations/DragUp.json';
import swipeLeftGesture from 'static/animations/SwipeLeft.json';
import swipeRightGesture from 'static/animations/SwipeRight.json';
import tapClickGesture from 'static/animations/TapClick.json';
import tapHoldGesture from 'static/animations/TapHold.json';
import Lottie from 'react-lottie-player';
import { useModelAnimationsContext } from '@nn-virtual-pen/education/features/learning-steps-page';
import * as Styled from './learning-steps-page.styled';
import {
  useUserContext,
  useContentContext,
  Product,
  Device,
  GestureAnimation,
} from '@nn-virtual-pen/education/data-access';
import { ModelViewer } from '@nn-virtual-pen/education/utils';
import { useStepModalContext } from './step-modal-context';
import { SubstepsIndicator } from './substeps-indicator';

interface ModelViewerWithAnimationsProps {
  height?: string;
}

export const ModelViewerWithAnimations: FC<ModelViewerWithAnimationsProps> = ({
  height,
}) => {
  const { setStepModalOpen } = useStepModalContext();
  const { activeStep, activeCategory } = useContentContext();
  const { configuration } = useUserContext();
  const { device, product } = configuration;
  const autocompleteRef = useRef(false);

  const {
    animationIndex,
    setAnimationIndex,
    animationComplete,
    animationCompleteRef,
    setAnimationComplete,
    onGestureCompleted,
    subStepComplete,
    setSubStepComplete,
    resetAnimationIndex,
  } = useModelAnimationsContext();

  useDidUpdateEffect(() => {
    if (subStepComplete) {
      setSubStepComplete(false);
      setAnimationComplete(false);
      if (animationIndex < activeStep.SubSteps.length - 1) {
        setAnimationIndex(inc(animationIndex));
      } else {
        setStepModalOpen(true);
      }
    }
  }, [subStepComplete]);

  useEffect(() => {
    resetAnimationIndex();
    setAnimationComplete(false);
  }, [activeStep]);

  useEffect(() => {
    if (
      path(['SubSteps', animationIndex, 'AnimationGesture'], activeStep) ===
      GestureAnimation.TapClick
    ) {
      autocompleteRef.current = true;
    } else if (autocompleteRef.current) {
      autocompleteRef.current = false;
    }
  }, [activeStep, animationIndex]);

  if (!path(['SubSteps', animationIndex], activeStep)) {
    return null;
  }

  const {
    AnimationStartAt,
    AnimationFinishAt,
    AnimationName,
    AnimationGesture,
    HiddenParts,
    ModelRotation,
    VoiceText
  } = activeStep.SubSteps[animationIndex];

  const animation = {
    [GestureAnimation.DragUp]: dragUpGesture,
    [GestureAnimation.DragDown]: dragDownGesture,
    [GestureAnimation.SlideLeft]: swipeLeftGesture,
    [GestureAnimation.SlideRight]: swipeRightGesture,
    [GestureAnimation.TapClick]: tapClickGesture,
  }[AnimationGesture];

  const onAnimationComplete = (value) => {
    if (autocompleteRef.current && animationCompleteRef.current) {
      setTimeout(() => {
        setSubStepComplete(true);
      }, 500);
    } else {
      setAnimationComplete(value);
    }
  };

  return (
    <>
      <SubstepsIndicator
        activeIndex={animationIndex}
        substepsLength={path(['SubSteps', 'length'], activeStep)}
      />
      <ModelViewer
        height={height}
        product={product as Product}
        device={device as Device}
        showStorage={activeCategory.Index === 1}
        animationConfig={{
          startAt: AnimationStartAt,
          finishAt: AnimationFinishAt,
          name: AnimationName,
          gesture: AnimationGesture,
          hiddenParts: HiddenParts,
        }}
        onAnimationCompleted={onAnimationComplete}
        onGestureCompleted={onGestureCompleted}
        modelRotation={ModelRotation}
        animationComplete={animationComplete}
        autoplay
        audioFile={VoiceText}
      />
      {animation && (
        <Styled.HiddenWrapper>
          <HiddenHelper hidden={!animationComplete} initiallyHidden>
            <Styled.LottieWrapper gesture={AnimationGesture}>
              <Lottie animationData={animation} loop play />
            </Styled.LottieWrapper>
          </HiddenHelper>
        </Styled.HiddenWrapper>
      )}
    </>
  );
};
