import styled from 'styled-components';

export const LottieWrapper = styled.div`
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
