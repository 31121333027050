export enum TranslationKey {
  loading = 'loading',
  welcome_text_1 = 'welcome_text_1',
  welcome_text_2 = 'welcome_text_2',
  welcome_passcode = 'welcome_passcode',
  welcome_no_passcode = 'welcome_no_passcode',
  welcome_validating_passcode = 'welcome_validating_passcode',
  welcome_expired_passcode = 'welcome_expired_passcode',
  welcome_invalid_passcode = 'welcome_invalid_passcode',
  welcome_enter_passcode = 'welcome_enter_passcode',
  page1_intro_text = 'page1_intro_text',
  page1_header = 'page1_header',
  page1_header_2 = 'page1_header_2',
  page1_select_product = 'page1_select_product',
  page1_select_country = 'page1_select_country',
  page1_product = 'page1_product',
  page1_country = 'page1_country',
  page1_terms = 'page1_terms',
  next_button = 'next_button',
  terms_of_condition = 'terms_of_condition',
  page1_terms_desktop_1 = 'page1_terms_desktop_1',
  goback_button = 'goback_button',
  page2_select = 'page2_select',
  page2_select_2 = 'page2_select_2',
  select_button = 'select_button',
  product_button = 'product_button',
  illustrative_pen = 'illustrative_pen',
  illustrative_pen_text = 'illustrative_pen_text',
  pen_text = 'pen_text',
  continue_button = 'continue_button',
  important = 'important',
  why_button = 'why_button',
  success_text = 'success_text',
  close_button = 'close_button',
  close_text = 'close_text',
  step = 'step',
  prev_step_button = 'prev_step_button',
  next_step_button = 'next_step_button',
  home_header = 'home_header',
  learning_header = 'learning_header',
  configure_header = 'configure_header',
  support_header = 'support_header',
  settings_country = 'settings_country',
  country_brazil = 'Brazil',
  country_mexico = 'Mexico',
  country_argentina = 'Argentina',
  country_columbia = 'Columbia',
  country_guatemala = 'Guatemala',
  privacy_button = 'privacy_button',
  terms_button = 'terms_button',
  invalid_text = 'invalid_text',
  support_text = 'support_text',
  support_text_body = 'support_text_body',
  call_button = 'call_button',
  email_button = 'email_button',
  validation_required = 'validation_required',
  validation_terms = 'validation_terms',
  read_more = 'read_more',
  product_disclaimer_header = 'product_disclaimer_header',
  product_disclaimer_text = 'product_disclaimer_text',
  push_button = 'push_button',
  cookie_consent = 'cookie_consent',
  cookie_accept = 'cookie_accept',
  cookie_reject = 'cookie_reject',
  page_not_found = 'page_not_found',
  return_home = 'return_home',
  congratulations_title = 'congratulations_title',
  congratulations_complete = 'congratulations_complete',
  congratulations_try_again = 'congratulations_try_again',
  congratulations_start_over = 'congratulations_start_over',
  exceed_limit = 'exceed_limit',
  promo_code = 'promo_code'
}
