import { useEffect, useRef } from 'react';

export const useDidUpdateEffect = (callback, deps) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    callback();
  }, deps);
};
