import { Dispatch, SetStateAction } from 'react';

export type CategoryType = number;
export type StepType = number;

export interface StepsType {
  [categoryIdx: number]: {
    [stepIdx: number]: boolean;
  };
}

type UpdateState<T> = {
  (newValue: T | ((value: T) => T)): void;
  reset: () => void;
};

export enum Device {
  FlexTouch = 'FlexTouch',
  FlexPen = 'FlexPen',
}

export enum FlexTouchProduct {
  Ozempic = 'Ozempic',
  Saxenda = 'Saxenda',
  Tresiba = 'Tresiba',
  Xultophy = 'Xultophy',
}

export enum FlexPenProduct {
  Levemir = 'Levemir',
  NovoRapid = 'NovoRapid',
  Victoza = 'Victoza',
}

export type Product = FlexTouchProduct | FlexPenProduct;

export interface PromoCode {
  Product: Product;
  Code: string;
}

export interface UserConfiguration {
  country: string;
  product: Product | '';
  device: Device | '';
  phoneSupport: string;
  emailSupport: string;
  promoCodes: PromoCode[];
}

export interface UserContextType {
  steps: StepsType;
  configuration: UserConfiguration;
  category: CategoryType;
  step: StepType;
  setSteps: UpdateState<StepsType>;
  setConfiguration: (config: UserConfiguration) => void;
  setStepAndCategory: (stepIdx: StepType, categoryIdx: CategoryType) => void;
  completeStep: () => void;
  userInteracted: boolean;
  setUserInteracted: Dispatch<SetStateAction<boolean>>;
}
