import React from 'react';
import { path } from 'ramda';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import {
  useUserContext,
  useContentContext,
  useTranslate,
  TranslationKey,
} from '@nn-virtual-pen/education/data-access';
import { FOOTER_HEIGHT } from '../constants';
import * as Styled from './footer.styled';
import { MobileMenu } from './mobile-menu';
import { Routes, useSupportContext } from '@nn-virtual-pen/education/utils';
import { useStepModalContext } from '../../step-modal-context';
import { Content } from '../learning-steps-mobile.types';

export const Footer = ({ content, setContent }) => {
  const { t } = useTranslate();
  const { steps } = useUserContext();
  const { activeStep, activeCategory, setNextStep } = useContentContext();
  const { setSupportOpen } = useSupportContext();
  const { setStepModalOpen } = useStepModalContext();

  const onMenuClick = () => {
    const routesFunctions = {
      [Routes.Learning]: () => setContent(Content.StepsList),
      [Routes.Support]: () => setSupportOpen(true),
    };
    return (routeName: Routes) => routesFunctions[routeName]();
  };

  const onNextClick = () => {
    if (path([activeCategory.Index, activeStep.Index], steps)) {
      setNextStep();
    } else {
      setStepModalOpen(true);
    }
  };

  return (
    <Styled.Footer height={FOOTER_HEIGHT}>
      {content !== Content.ModelViewer ? (
        <Styled.BackButtonWrapper
          onClick={() => setContent(Content.ModelViewer)}
        >
          <Styled.BackButton />
          {t(TranslationKey.goback_button)}
        </Styled.BackButtonWrapper>
      ) : (
        <Styled.ReadMoreButton
          onClick={() => setContent(Content.StepInformation)}
        />
      )}
      {content === Content.ModelViewer && (
        <ActionButton
          variant={ActionButtonVariant.primary}
          size={ActionButtonSize.small}
          onClick={onNextClick}
        >
          {t(TranslationKey.next_step_button)}
        </ActionButton>
      )}
      <MobileMenu onClick={onMenuClick()} />
    </Styled.Footer>
  );
};
