import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { DropDown } from './drop-down';
import { DropDownFieldProps } from './drop-down.types';
import { ErrorMessage } from '../error-message';

export const DropDownField: FC<DropDownFieldProps> = ({
  name,
  hideError,
  control,
  errors,
  placeholder,
  disabled,
  disabledText,
  options,
  isLoading,
}) => {
  const error = !!errors[name];

  return (
    <div>
      <Controller
        name={name}
        control={control}
        as={
          <DropDown
            name={name}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
            disabledText={disabledText}
            isLoading={isLoading}
            options={options}
          />
        }
      />
      {!disabled && (
        <ErrorMessage
          hideError={hideError}
          errors={errors}
          name={name}
        />
      )}
    </div>
  );
};
