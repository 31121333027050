import styled from 'styled-components';
import { path } from 'ramda';

export const Wrapper = styled.div`
  display: flex;
  background-color: ${path(['theme', 'palette', 'introBg'])};
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  font-size: 18px;
`;
