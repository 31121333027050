import React, { FC } from 'react';
import { ProgressViewer } from '@nn-virtual-pen/education/ui';
import { pipe, propOr, values, filter, identity, length, path } from 'ramda';
import * as Styled from './header.styled';
import { HEADER_HEIGHT } from '../constants';
import {
  StepsType,
  useUserContext,
  useContentContext,
} from '@nn-virtual-pen/education/data-access';

interface HeaderProps {
  onClick: () => void;
}

export const Header: FC<HeaderProps> = ({ onClick }) => {
  const { steps } = useUserContext();
  const { activeStep, activeCategory } = useContentContext();

  const getProgressValue: (steps: StepsType) => number = pipe(
    propOr({}, `${activeCategory.Index}`),
    values,
    filter(identity),
    length
  );

  return (
    <Styled.Header headerHeight={HEADER_HEIGHT} onClick={onClick}>
      <Styled.Wrapper>
        <Styled.Title>
          Step {activeStep.LearningStep} - {activeStep.Title}
        </Styled.Title>
        <Styled.SubTitle>{activeStep.SubTitle}</Styled.SubTitle>
      </Styled.Wrapper>
      <ProgressViewer
        stepsLength={path(['Steps', 'length'], activeCategory)}
        value={getProgressValue(steps)}
        size={40}
      />
    </Styled.Header>
  );
};
