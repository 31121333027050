import React from 'react';
import { Canvas as R3fCanvas, ResizeOptions } from 'react-three-fiber';
import * as THREE from 'three';
import { ResizeObserver } from '@juggle/resize-observer';

export const Canvas = ({ children, cameraPosition = [0, 0, 0] }) => (
  <R3fCanvas
    colorManagement
    shadowMap
    camera={{
      zoom: 0.9,
      position: new THREE.Vector3(...cameraPosition),
      isPerspectiveCamera: true
    }}
    pixelRatio={window.devicePixelRatio}
    resize={{ polyfill: ResizeObserver } as ResizeOptions}
  >
    {children}
    <ambientLight intensity={0.3} />
    <spotLight position={[100, 30, 100]} penumbra={1} />
    <spotLight position={[-100, 30, 100]} penumbra={1} />
  </R3fCanvas>
);
