import { useEffect, useState } from 'react';
import { prop } from 'ramda';

export const useProductMaterials = (
  gltf,
  currentMaterial,
  materialKeys
) => {
  const [materialsMap, setMaterialsMap] = useState(new Map());

  useEffect(() => {
    gltf.parser.getDependencies('material').then((materials) => {
      console.info('[Textures]', materials.map(prop('name')));

      const m = materials.reduce((prev, material) => {
        if (!materialKeys.includes(material.name)) {
          return prev;
        }

        return [...prev, [material.name, material]];
      }, []);

      setMaterialsMap(new Map(m));
    });
  }, [gltf]);

  return materialsMap.get(currentMaterial);
};
