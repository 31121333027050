import styled from 'styled-components';
import { path } from 'ramda';

export const NavLink = styled.li`
  display: inline-block;
  cursor: pointer;
  color: ${path(['theme', 'palette', 'nav'])};
  font-weight: ${path(['theme', 'fontWeight', 'medium'])};

  :not(:last-of-type) {
    margin-right: 32px;
  }
`;
