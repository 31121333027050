import React, { useContext, createContext, FC, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { find, propEq } from 'ramda';
import countryInformation from 'static/data/country-information.json';
import {
  DEFAULT_STEP,
  DEFAULT_CATEGORY,
  DEFAULT_STEPS,
} from './user-context.consts';
import {
  UserContextType,
  CategoryType,
  StepType,
  StepsType,
  UserConfiguration,
} from './user-context.types';

const CreateUserContext = createContext<UserContextType>(undefined);

export const UserContext: FC = ({ children }) => {
  const [userInteracted, setUserInteracted] = useState(false);

  const [category, setCategory] = useLocalStorageState<CategoryType>(
    'category',
    DEFAULT_CATEGORY
  );

  const [step, setStep] = useLocalStorageState<StepType>('step', DEFAULT_STEP);

  const [steps, setSteps] = useLocalStorageState<StepsType>(
    'steps',
    DEFAULT_STEPS
  );

  const [configuration, setConfiguration] = useLocalStorageState<
    UserConfiguration
  >('configuration', {
    country: '',
    product: '',
    device: '',
    emailSupport: '',
    phoneSupport: '',
    promoCodes: null,
  });

  const onSetConfiguration = (config) => {
    if (config.country) {
      const { PhoneSupport, EmailSupport, PromoCode } = find(
        propEq('Country', config.country)
      )(countryInformation) as any

      return setConfiguration({
        ...config,
        emailSupport: EmailSupport,
        phoneSupport: PhoneSupport,
        promoCodes: PromoCode,
      });
    }

    setConfiguration(config);
  }

  const setStepAndCategory = (stepIdx: StepType, categoryIdx: CategoryType) => {
    setStep(stepIdx);
    setCategory(categoryIdx);
  };

  const completeStep = () => {
    setSteps((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [step]: true,
      },
    }));
  };

  return (
    <CreateUserContext.Provider
      value={{
        steps,
        configuration,
        step,
        category,
        setSteps,
        setConfiguration: onSetConfiguration,
        setStepAndCategory,
        completeStep,
        userInteracted,
        setUserInteracted,
      }}
    >
      {children}
    </CreateUserContext.Provider>
  );
};

export const useUserContext = () => useContext(CreateUserContext);
