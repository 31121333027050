import React, { FC, InputHTMLAttributes } from 'react';
import * as Styled from './toggle.styled';

/* eslint-disable-next-line */
export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Toggle: FC<ToggleProps> = ({
  disabled,
  checked,
  onChange,
  ...x
}) => {
  return (
    <Styled.Wrapper>
      <Styled.MaskedInput
        disabled={disabled}
        checked={checked}
        onChange={(e: any) => {
          e.persist();
          onChange(e.target.checked);
        }}
      />
      <Styled.Control />
    </Styled.Wrapper>
  );
};

export default Toggle;
