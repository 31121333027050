import React, { FC, useEffect, useRef, useState } from 'react';
import { inc } from 'ramda';
import { AnimationState, ProgressViewer } from '@nn-virtual-pen/education/ui';
import { useTranslate, TranslationKey } from '@nn-virtual-pen/education/data-access';
import * as Styled from './push-and-hold.styled';

interface PushAndHoldProps {
  animationState: AnimationState;
  onAnimationStart: () => void;
  onAnimationStop: () => void;
  onGestureCompleted: () => void;
}

export const PushAndHold: FC<PushAndHoldProps> = ({
  onGestureCompleted,
  onAnimationStart,
  onAnimationStop,
}) => {
  const interval = useRef<NodeJS.Timer>();
  const counter = useRef<number>(0);
  const [counterState, setCounterState] = useState<number>(0);
  const { t } = useTranslate();

  const setCounter = () => {
    const next = inc(counter.current)
    counter.current = next;
    setCounterState(next);
  }

  const onPush = () => {
    onAnimationStart();
    setCounter();
    interval.current = setInterval(() => {
      if (counter.current === 7) {
        onGestureCompleted();
        clearInterval(interval.current);
      } else {
        setCounter();
      }
    }, 1000);
  };

  const onRelease = () => {
    if (counter.current < 6) {
      clearInterval(interval.current);
      onAnimationStop();
      counter.current = 0;
      setCounterState(0);
    }
  }

  useEffect(() => () => {
    clearTimeout(interval.current);
  }, []);

  return (
    <Styled.PushAndHold
      onMouseDown={onPush}
      onMouseUp={onRelease}
      onTouchStart={onPush}
      onTouchEnd={onRelease}
      onContextMenu={() => {
        return false
      }}
    >
      <ProgressViewer
        size={60}
        text={t(TranslationKey.push_button)}
        stepsLength={6}
        value={counterState}
      />
    </Styled.PushAndHold>
  );
};
