import { fieldOptionsMap } from '@nn-virtual-pen/utils';

const fieldCountryOptionsMap = (value, t) => ({
  value,
  label: t(`country_${value.toLowerCase()}`),
});

export const transformCountryInformation = (data, t) => {
  return data.reduce(
    (prev, current) => {
      const products: string[] = current.Pen.reduce(
        (prev, pen) => [...prev, ...pen.Product],
        []
      );

      const productDevice: string[][] = current.Pen.reduce(
        (prev, pen) => [
          ...prev,
          ...pen.Product.map((product) => [product, pen.Device]),
        ],
        []
      );

      return {
        fieldOptions: {
          country: [
            ...prev.fieldOptions.country,
            fieldCountryOptionsMap(current.Country, t),
          ],
          product: new Map([
            ...prev.fieldOptions.product,
            [current.Country, products.map(fieldOptionsMap)],
          ]),
        },
        productDevice: new Map([
          ...prev.productDevice,
          [current.Country, new Map([...productDevice])],
        ]),
      };
    },
    {
      fieldOptions: {
        country: [],
        product: new Map(),
      },
      productDevice: new Map(),
    }
  );
};

export const getProductImageSrcMap = (nodes) => {
  return nodes.reduce((prev, current) => {
    return new Map([
      ...prev,
      [current.name, current.childImageSharp.fluid.srcSetWebp],
    ]);
  }, new Map());
};
