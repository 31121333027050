import React, { FC } from 'react';
import { reverse } from 'ramda';
import {
  ActionButtonIconPlacement,
  ActionButtonProps,
} from './action-button.types';
import * as Styled from './action-button.styled';
import { LoadingIndicator } from '../loading-indicator';

export const ActionButton: FC<ActionButtonProps> = ({
  children,
  disabled,
  loading,
  variant,
  size,
  width,
  onClick,
  IconComponent,
  bolded,
  fullWidth,
  iconPlacement = ActionButtonIconPlacement.left,
}) => {
  const ContentArray = [
    <React.Fragment key={1}>
      {IconComponent && (
        <Styled.IconWrapper variant={variant} size={size}>
          <IconComponent />
        </Styled.IconWrapper>
      )}
    </React.Fragment>,
    <Styled.Text key={3} hasIcon={!!IconComponent} bolded={bolded} size={size}>
      {children}
    </Styled.Text>,
  ];

  const displayContent = () => {
    if (iconPlacement === ActionButtonIconPlacement.left) {
      return ContentArray;
    } else {
      return reverse(ContentArray);
    }
  };

  return (
    <Styled.Button
      disabled={disabled || loading}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      width={width}
      onClick={onClick}
    >
      <Styled.Content>
        {loading ? <LoadingIndicator align="center" /> : displayContent()}
      </Styled.Content>
    </Styled.Button>
  );
};
