import styled from 'styled-components';
import { prop } from 'ramda';

export const Wrapper = styled.button.attrs({ type: 'button' })<{ size: number }>`
  width: ${prop('size')}px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  margin-top: 10px;

  > img {
    width: 100%;
  }
`;