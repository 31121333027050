export const getTextColor = (props) => {
  if (props.disabled) {
    return props.theme.palette.disabledText;
  }

  if (props.hasValue) {
    return props.theme.palette.black;
  }

  return props.theme.palette.jumbo;
};
