import { path } from 'ramda';
import { InterpolationFunction } from 'styled-components';

const gap = 32;

const breakpoints = {
  //   (default)	Mobile-portrait
  // min-width: 480px	Mobile-landscape (and larger)
  // min-width: 768px	Tablet-portrait (and larger)
  // min-width: 992px	Tablet-landscape (and larger)
  // min-width: 1200px	Laptops (and langer)
  // The container horizontal gap, which acts as the offset for breakpoints
  // mobile: 321,
  tablet: 769 + 2 * gap,
  desktop: 1088 + 2 * gap,
};

/**
 * Looking for proper color name?
 * https://chir.ag/projects/name-that-color/#756F76
 */
export const theme = {
  palette: {
    white: '#ffffff',
    black: '#000000',
    nav: '#18191F',
    nnBlue: '#001965',
    seaBlue: '#005ad2',
    lightBlue: '#3b97de',
    blurredCircle: '#8c30f5',
    selago: '#E4EDFC',
    oceanGreen: '#2a918b',
    forestGreen: '#3F9C35',
    justGreen: '#05E41B',
    stormGrey: '#6E7191',
    jumbo: '#7E7F87',
    graniteGrey: '#939aa7',
    sandGrey: '#ccc5bd',
    scorpionGrey: '#595959',
    mercuryGrey: '#E5E5E5',
    silverChalice: '#A8A8A8',
    introBg: '#f2fbff',
    whisper: '#EFF0F6',
    mystic: '#E1E4ED',
    lavaRed: '#E6553F',
    yellowSun: '#EAAB00',
    rosePink: '#eea7bf',
    disabledBg: 'rgb(243, 242, 241)',
    disabledText: 'rgb(161, 159, 157)',
    greyedBg: 'rgba(196, 196, 196, 0.1)',
    moreGreyedBg: 'rgba(196, 196, 196, 0.3)',
    error: '#CF4D39',
    doveGray: '#766D6D',
  },
  breakpoints,
  media: {
    tablet: `screen and (min-width: ${breakpoints.tablet}px)`,
    desktop: `screen and (min-width: ${breakpoints.desktop}px)`,
  },
  header: {
    offset: 72,
  },
  container: {
    gap,
    offset: gap * 2,
  },
  fontFamily: {
    apis: `'Apis', sans-serif`,
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  zIndex: {
    gesture: 8,
    pen: 9,
    header: 10,
    mobileMenu: 11,
    modal: 12,
  },
};

export const getColor = (color: keyof typeof theme.palette) =>
  path(['theme', 'palette', color]) as InterpolationFunction<any>;
