import React, { FC, useEffect, useState, useRef } from 'react';
import { theme } from '@nn-virtual-pen/ui';
import * as Styled from './modal.styled';
import { compose, dec } from 'ramda';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

export interface ModalProps {
  opened: boolean;
  onClose: () => void;
  IconComponent: FC;
  AdditionalButtons?: FC;
  title: string;
  description: string;
  state: 'success' | 'warning';
  autoClose?: boolean;
  autoCloseSeconds?: number;
}

export const Modal: FC<ModalProps> = ({
  opened,
  onClose,
  IconComponent,
  state,
  title,
  description,
  AdditionalButtons,
  autoClose,
  autoCloseSeconds = 5,
}) => {
  const { t } = useTranslate();
  const [seconds, setSeconds] = useState<number>(autoCloseSeconds);
  const intervalRef = useRef<NodeJS.Timer>();
  const timeoutRef = useRef<NodeJS.Timer>();
  const color = {
    success: theme.palette.forestGreen,
    warning: theme.palette.yellowSun,
  };

  const releaseAutoClose = () => {
    if (autoClose) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      intervalRef.current && clearInterval(intervalRef.current);
      setSeconds(autoCloseSeconds);
    }
  };

  const close = () => {
    onClose();
    releaseAutoClose();
  };

  useEffect(() => {
    if (opened) {
      if (autoClose) {
        timeoutRef.current = setTimeout(close, seconds * 1000);
        intervalRef.current = setInterval(compose(setSeconds, dec), 1000);
      }
    } else {
      releaseAutoClose();
    }

    return releaseAutoClose;
  }, [autoClose, opened]);

  if (!opened) return null;
  return (
    <Styled.Dialog>
      <Styled.TopModal>
        <Styled.ButtonWrapper>
          <Styled.CloseButton onClick={close} />
        </Styled.ButtonWrapper>
      </Styled.TopModal>
      {IconComponent && (
        <Styled.Icon color={color[state]}>
          <IconComponent />
        </Styled.Icon>
      )}
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      {AdditionalButtons && (
        <Styled.AdditionalButtons>
          <AdditionalButtons />
        </Styled.AdditionalButtons>
      )}
      {autoClose && (
        <Styled.AutoClose>
          {t(TranslationKey.close_text, {
            countdownSeconds: seconds.toString(),
          })}
        </Styled.AutoClose>
      )}
    </Styled.Dialog>
  );
};

export default Modal;
