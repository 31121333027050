import styled from 'styled-components';
import { path } from 'ramda';

export const PushAndHold = styled.button`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 12%;

  background: none;
  outline: none;
  border: none;

  @media ${path(['theme', 'media', 'desktop'])} {
    right: 27%;
  }
`;
