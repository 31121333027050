import { getIsBrowser } from '@nn-virtual-pen/utils';

type LearningStepNavigationGtmEventTrigger = 'previous_step_button' | 'next_step_button' | 'page_view';

// todo: supposed to be inside education/utils, but for some reason gatsby SSR rejects to build
export const learningStepNavigationGtmEvent = (
  stepName: string,
  category: number,
  step: number,
  device: string,
  product: string,
  country: string,
  trigger: LearningStepNavigationGtmEventTrigger,
): void => {
  if (!getIsBrowser()) {
    return;
  }

  // @ts-ignore
  window.trackGoogleAnalyticsEvent('learning_step_navigation', {
    step_name: stepName,
    category,
    step,
    device,
    product,
    country,
    trigger,
  });
}
