export const getActiveState = (props) => {
  if (props.disabled) {
    return props.theme.palette.disabledBg;
  }

  if (!props.hasValue) {
    return props.theme.palette.whisper;
  }

  if (props.isMenuOpen) {
    return props.theme.palette.whisper;
  }

  return props.theme.palette.mystic;
};
