import React from 'react';
import { Controller } from 'react-hook-form';
import { Toggle } from './toggle';
import { ErrorMessage } from '../error-message';

export const ToggleField = ({
  disabled,
  hideError,
  control,
  errors,
  name,
  label,
}) => (
  <div>
    <Controller
      name={name}
      control={control}
      as={
          <Toggle disabled={disabled} />
      }
    />
    <ErrorMessage hideError={hideError} errors={errors} name={name} />
  </div>
);
