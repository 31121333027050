import React, {
  Suspense,
  useMemo,
  useState,
  FC,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject
} from 'react';
import { Canvas } from './canvas';
import { Html } from 'drei';
import Lottie from 'react-lottie-player';
import { Model } from './Model';
import {
  AnimationControl,
  AnimationState,
  HiddenHelper,
} from '@nn-virtual-pen/education/ui';
import { useSupportContext } from '@nn-virtual-pen/education/utils';
import {
  Device,
  GestureAnimation,
  Product,
  useUserContext,
  useAudioContext
} from '@nn-virtual-pen/education/data-access';
import { useStepModalContext } from '@nn-virtual-pen/education/features/learning-steps-page';
import {
  modelCameraPositions,
  modelProductMaterials,
  modelPaths,
  storagePaths,
  storageProductMaterials,
} from './model-viewer.consts';
import loadingJson from 'static/animations/loading.json';
import * as Styled from './model-viewer.styled';
import { PushAndHold } from './push-and-hold';
import { AudioPlayer } from './audio-player';

const Loader = () => (
  <Html style={{ width: '200px', textAlign: 'center' }} center>
    <div>
      <Lottie animationData={loadingJson} loop play />
    </div>
  </Html>
);

interface ModelViewerProps {
  product: Product;
  device: Device;
  animationConfig: {
    startAt: number;
    finishAt: number | null;
    name: string;
    gesture: GestureAnimation;
    hiddenParts: string[];
  };
  modelRotation: number;
  onAnimationCompleted?: Dispatch<SetStateAction<boolean>>;
  onGestureCompleted?: () => void;
  allowRotation?: boolean;
  height?: string;
  autoplay?: boolean;
  animationComplete?: boolean;
  showStorage?: boolean;
  audioFile?: string;
}

export const ModelViewer: FC<ModelViewerProps> = ({
  animationConfig = {},
  product,
  device,
  showStorage,
  allowRotation,
  height,
  onAnimationCompleted,
  onGestureCompleted,
  autoplay,
  animationComplete,
  modelRotation,
  audioFile,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [state, setState] = useState<AnimationState>(AnimationState.play);
  const animationRef = useRef() as MutableRefObject<NodeJS.Timer>;
  const { trackRef } = useAudioContext();
  const { supportOpen } = useSupportContext();
  const { stepModalOpen } = useStepModalContext();
  const { configuration } = useUserContext();

  useEffect(() => {
    setState(AnimationState.play);

    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    }
  }, [animationConfig.name]);

  const onAnimationStart = () => {
    setState(AnimationState.playing);
  };

  const onAnimationStop = (complete?: boolean) => {
    if (complete) {
      onAnimationCompleted(true);
    }
    setState(AnimationState.play);
  };

  const onClick = () => {
    setState((prevState) => {
      if (prevState === AnimationState.play) {
        void trackRef.current.play();
        return AnimationState.playing;
      }
      if (prevState === AnimationState.playing) {
        return AnimationState.canceling;
      }

      return prevState;
    });
  };

  const onAnimationLoaded = () => {
    if (autoplay) {
      animationRef.current = setTimeout(() => {
        setState(AnimationState.playing);
      }, 1000);
    }
  };

  const onTapClick = () => {
    if (
      animationConfig.gesture === GestureAnimation.TapClick &&
      state !== AnimationState.playing
    ) {
      onAnimationStart();
    }
  };

  const cameraPosition = useMemo(() => modelCameraPositions[device], [device]);

  const productMaterials = useMemo(
    () =>
      (showStorage ? storageProductMaterials : modelProductMaterials)[device],
    [device, showStorage]
  );
  const productMaterialKey = useMemo(() => productMaterials[product], [
    productMaterials,
    product,
    showStorage
  ]);
  const path = useMemo(
    () => (showStorage ? storagePaths : modelPaths)[device],
    [device, showStorage]
  );

  return (
    <Styled.Wrapper height={height} onClick={onTapClick} onTouchEnd={onTapClick}>
      <Styled.AnimationProgress progress={progress} />
      <Styled.AnimationControl hidden={!animationConfig.name}>
        <AnimationControl size={50} action={state} onClick={onClick} />
        <AudioPlayer audioFile={audioFile} />
      </Styled.AnimationControl>
      <Styled.CanvasWrapper
        {...(!supportOpen && !stepModalOpen && { rotation: modelRotation })}
      >
        <Canvas cameraPosition={cameraPosition}>
          <Suspense fallback={<Loader />}>
            <Model
              onAnimationLoaded={onAnimationLoaded}
              onAnimationStart={onAnimationStart}
              onAnimationStop={onAnimationStop}
              setProgress={setProgress}
              onGestureCompleted={onGestureCompleted}
              animationConfig={animationConfig}
              animationState={state}
              productMaterials={productMaterials}
              productMaterialKey={productMaterialKey}
              path={path}
              allowRotation={allowRotation}
              product={configuration.product}
            />
          </Suspense>
        </Canvas>
      </Styled.CanvasWrapper>
      {animationConfig.gesture === GestureAnimation.TapHold && (
        <HiddenHelper hidden={!animationComplete}>
          <PushAndHold
            animationState={state}
            onAnimationStart={onAnimationStart}
            onAnimationStop={onAnimationStop}
            onGestureCompleted={onGestureCompleted}
          />
        </HiddenHelper>
      )}
    </Styled.Wrapper>
  );
};
