import styled from 'styled-components';
import { path } from 'ramda';
import { getBorderColor } from './text-input.helpers';

export const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
`;

export const Label = styled.label`
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
  color: ${path(['theme', 'palette', 'doveGray'])};
`;

export const InputWrapper = styled.span<{ error: boolean; success: boolean }>`
  height: 37px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid ${getBorderColor};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;