import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface AdminPanelDataAccessProps {}

const StyledAdminPanelDataAccess = styled.div`
  color: pink;
`;

export const AdminPanelDataAccess = (props: AdminPanelDataAccessProps) => {
  return (
    <StyledAdminPanelDataAccess>
      <h1>Welcome to admin-panel-data-access!</h1>
    </StyledAdminPanelDataAccess>
  );
};

export default AdminPanelDataAccess;
