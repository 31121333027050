import styled from 'styled-components';
import { LogoSize } from './logo.types';

const getWidth = ({ size }) => {
  const sizes = {
    [LogoSize.small]: '58px',
    [LogoSize.medium]: '68px',
    [LogoSize.normal]: '79px',
  };

  return sizes[size] || 'auto';
};

export const Img = styled.img`
  width: ${getWidth};
`;
