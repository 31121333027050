import styled from 'styled-components';

export const LearningStepsPage = styled.div`
  height: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
`;

export const ModelViewerWrapper = styled.div<{ visible: boolean; }>`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  height: 100%;
`;
