import React, { FC, SVGProps } from 'react';

export const Svg: FC<SVGProps<SVGSVGElement>> = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
