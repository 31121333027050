import {
  Device,
  FlexTouchProduct,
  FlexPenProduct,
} from '@nn-virtual-pen/education/data-access';

export const animationStepCount = 100;

export const animationDragSpeed = 140;

export const modelPaths = {
  [Device.FlexPen]: '/models/FlexPen/Flex_Pen_Latest_4.gltf',
  [Device.FlexTouch]: '/models/FlexTouch/Flex_touch_latest5.gltf',
};

export const storagePaths = {
  [Device.FlexPen]: '/models/FlexPen_Storage/FlexPen_Storage_6.gltf',
  [Device.FlexTouch]: '/models/FlexTouch_Storage/Flex_Touch_Storage6.gltf',
};

export const modelProductMaterials = {
  [Device.FlexPen]: {
    [FlexPenProduct.Levemir]: 'Levemir',
    [FlexPenProduct.Victoza]: 'Victoza',
    [FlexPenProduct.NovoRapid]: 'Novorapid',
  },
  [Device.FlexTouch]: {
    [FlexTouchProduct.Ozempic]: 'Material_Ozempic',
    [FlexTouchProduct.Xultophy]: 'Material_Xultophy',
    [FlexTouchProduct.Tresiba]: 'Material_Tresiba',
    [FlexTouchProduct.Saxenda]: 'Material_Saxenda',
  },
};

export const storageProductMaterials = {
  [Device.FlexPen]: {
    [FlexPenProduct.Levemir]: 'Material #3',
    [FlexPenProduct.Victoza]: 'Material #16',
    [FlexPenProduct.NovoRapid]: 'Material #15',
  },
  [Device.FlexTouch]: {
    [FlexTouchProduct.Ozempic]: 'Material_Ozempic',
    [FlexTouchProduct.Xultophy]: 'Material_Xultophy',
    [FlexTouchProduct.Tresiba]: 'Material_Tresiba',
    [FlexTouchProduct.Saxenda]: 'Material_Saxenda',
  },
};

export const modelCameraPositions = {
  [Device.FlexPen]: [0, 0, 30],
  [Device.FlexTouch]: [0, 0, 30],
};
