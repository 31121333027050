import React, { FC } from 'react';
import Img from 'static/nn-logo.svg';
import * as Styled from './logo.styled';
import { LogoProps } from './logo.types';

export const Logo: FC<LogoProps> = ({ size }) => {
  return (
    <a
      title="Novo Nordisk Global"
      href="https://www.novonordisk.com/"
      target="_blank"
      rel="noreferrer"
    >
      <Styled.Img size={size} src={Img} alt="" />
    </a>
  );
};
