import React, { FC, useEffect, useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useUserContext } from '../user-context/user-context';
import { getUserLanguage } from './helpers';

const DEFAULT_COUNTRY = 'brazil'

export const TranslateProvider: FC = ({ children }) => {
  const userLanguage = getUserLanguage();
  const [messages, setMessages] = useState({});
  const { configuration } = useUserContext();

  const country = useMemo(
    () => configuration.country.toLowerCase() || DEFAULT_COUNTRY,
    [configuration.country],
  );

  const getDefaultLanguage = (country: string) => ({
    brazil: 'pt',
    argentina: 'es',
    guatemala: 'es',
    chile: 'es',
  }[country])

  const fetchDefaultTranslations = () => {
    fetch(`/translations/${getDefaultLanguage(country)}-${country}.json`)
      .then(response => response.json())
      .then(setMessages);
  }

  const fetchTranslations = async () => {
    try {
      const data = await fetch(`/translations/${userLanguage}-${country}.json`)
        .then(response => response.json())

      if (!Object.values(data).length) {
        fetchDefaultTranslations();
      } else {
        setMessages(data)
      }
    } catch (e) {
      console.error(e);
      fetchDefaultTranslations();
    }
  }

  useEffect(() => {
    void fetchTranslations();
  }, [country]);

  return (
    <IntlProvider messages={messages} locale={userLanguage}>
      {children}
    </IntlProvider>
  )
};
